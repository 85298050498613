<template>
  <!-- Home Banner -->
  <section class="banner-section-fifteen">
    <div class="sec-bg">
      <img
        src="@/assets/img/bg/ent-banner-bg-01.png"
        class="ban-blue-bg"
        alt="Img"
      />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <div
            class="banner-content banner-content-fifteen aos"
            data-aos="fade-up"
          >
            <h2>Painless, Safe Treatment</h2>
            <h1>Hear what you have been Missing</h1>
            <div class="banner-btns-fifteen">
              <router-link to="/appointments" class="btn btn-primary me-2"
                >Make an Appointment</router-link
              >
              <router-link to="/booking" class="btn btn-primary btns-primarytwo"
                >Quick Consultation</router-link
              >
            </div>
            <div class="d-sm-flex align-items-center">
              <div class="aboutus-companyimg">
                <a href="#">
                  <img
                    src="@/assets/img/banner-fifteen-vd.jpg"
                    alt="image"
                    class="img-fluid"
                  />
                </a>
                <a
                  href="https://www.youtube.com/embed/VXjGBqpbra0"
                  data-fancybox
                >
                  <div class="playicon">
                    <span>
                      <i class="fa-solid fa-play"></i>
                    </span>
                  </div>
                </a>
              </div>
              <div class="support-consult-main">
                <div class="support-consult">
                  <div class="support-consult-img">
                    <img
                      src="@/assets/img/icons/customer-service-2.svg"
                      alt="Service"
                    />
                  </div>
                  <div class="support-consult-right">
                    <h6>24/7 Support</h6>
                    <div class="rating rating-fifteen">
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                    </div>
                  </div>
                </div>
                <div class="support-consult">
                  <div class="support-consult-img">
                    <img
                      src="@/assets/img/icons/customer-service-1.svg"
                      alt="Service"
                    />
                  </div>
                  <div class="support-consult-right">
                    <h6>Online Consultation</h6>
                    <span>Just 60 Secs</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="banner-right-fifteen">
            <img
              src="@/assets/img/banner-fifteen-ryt.png"
              alt="image"
              class="img-fluid"
            />
            <div class="banner-right-fifteenone">
              <img src="@/assets/img/ban-fift-icon1.png" alt="Icon" />
            </div>
            <div class="banner-right-fifteentwo">
              <img src="@/assets/img/ban-fift-icon2.png" alt="Icon" />
            </div>
            <div class="banner-right-fifteenthree">
              <img src="@/assets/img/ban-fift-icon3.png" alt="Icon" />
            </div>
            <div class="banner-right-fifteenfour">
              <img src="@/assets/img/ban-fift-icon4.png" alt="Icon" />
            </div>
            <div class="banner-right-fifteenseven">
              <img src="@/assets/img/ban-fift-icon7.png" alt="Icon" />
            </div>
            <div class="banner-right-fifteeneight">
              <img src="@/assets/img/ban-fift-icon8.png" alt="Icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Home Banner -->

  <!-- services Section -->
  <section class="services-section-fifteen">
    <div class="service-bg-icon">
      <img src="@/assets/img/serive-bg-icon.png" alt="Icon" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header-fifteen text-center">
            <h2>Our <span>Services</span></h2>
            <p>
              Our goal is to give the patient maximum relief within minimal pain
              inflicted
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="item">
            <div class="doctor-profile-widget-fifteen">
              <div class="doc-pro-img">
                <router-link to="/doctor-profile">
                  <div class="doctor-profile-img">
                    <img
                      src="@/assets/img/service/service-3.jpg"
                      class="img-fluid"
                      alt="Service"
                    />
                  </div>
                </router-link>
              </div>
              <div class="doc-content-bottom">
                <div class="doc-content-split">
                  <router-link to="/doctor-profile">EAR Treatment</router-link>
                </div>
                <p>
                  we are dedicated to providing the highest standard of care for
                  all ear-related concerns
                </p>
                <span>5+ Services</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="item">
            <div class="doctor-profile-widget-fifteen">
              <div class="doc-pro-img">
                <router-link to="/doctor-profile">
                  <div class="doctor-profile-img">
                    <img
                      src="@/assets/img/service/service-2.jpg"
                      class="img-fluid"
                      alt="Service"
                    />
                  </div>
                </router-link>
              </div>
              <div class="doc-content-bottom">
                <div class="doc-content-split">
                  <router-link to="/doctor-profile">Nose and Sinus</router-link>
                </div>
                <p>
                  The cranial aspect of the human body commanded respect from
                  patients and doctors alike.
                </p>
                <span>10+ Services</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="item">
            <div class="doctor-profile-widget-fifteen">
              <div class="doc-pro-img">
                <router-link to="/doctor-profile">
                  <div class="doctor-profile-img">
                    <img
                      src="@/assets/img/service/service-1.jpg"
                      class="img-fluid"
                      alt="Service"
                    />
                  </div>
                </router-link>
              </div>
              <div class="doc-content-bottom">
                <div class="doc-content-split">
                  <router-link to="/doctor-profile"
                    >Throat / Larynx Surgery</router-link
                  >
                </div>
                <p>
                  Laryngoplasty was introduced and popularised by Isshiki in the
                  1970’s.
                </p>
                <span>15+ Services</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /services Section -->

  <!-- Patients Section -->
  <div class="Patients-section-fifteen">
    <div class="patient-fifteen-icon">
      <img src="@/assets/img/fifteen-bg-icon-3.png" alt="Icon" />
    </div>
    <div class="sec-bg-img">
      <img src="@/assets/img/bg/rectangle-bg.png" class="blue-rec-bg" alt="Img" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="patients-left-front patients-left-img">
            <img
              src="@/assets/img/patients-img-fifteen.png"
              alt="Patients"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="section-header-fifteen section-header-fifteenpatient">
            <h2>Who <span>We Are</span></h2>
            <p>
              Our goal is to give the patient maximum relief within minimal pain
              inflicted
            </p>
          </div>
          <p>
            At Doccure, we are dedicated to providing comprehensive and
            compassionate care for all your ENT needs. Our team of experienced
            and highly skilled ENT specialists is committed to ensuring the
            well-being of our patients through state-of-the-art medical
            services.
          </p>
          <p>
            At the core of our mission is a commitment to excellence in patient
            care. We strive to enhance the quality of life for individuals of
            all ages by addressing a wide range of ENT issues with precision,
            empathy, and the latest medical advancements.
          </p>
          <div class="row">
            <div class="col-md-4">
              <div class="service-patient-inflict">
                <div class="service-patient-inflictimg">
                  <span>
                    <img src="@/assets/img/icons/we-are-icon3.svg" alt="Icon" />
                  </span>
                </div>
                <div class="clients-fifteen-span">
                  <h3 class="counter">14,500</h3>
                </div>
                <h6>ENT Surgery</h6>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-patient-inflict">
                <div class="service-patient-inflictimg">
                  <span>
                    <img src="@/assets/img/icons/we-are-icon2.svg" alt="Icon" />
                  </span>
                </div>
                <div class="clients-fifteen-span">
                  <h3 class="counter">50,000</h3>
                  <span>+</span>
                </div>
                <h6>Happy Patients</h6>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-patient-inflict">
                <div class="service-patient-inflictimg">
                  <span>
                    <img src="@/assets/img/icons/we-are-icon1.svg" alt="Icon" />
                  </span>
                </div>
                <div class="clients-fifteen-span">
                  <h3 class="counter">30</h3>
                  <span>+</span>
                </div>
                <h6>Years of Service</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Patients Section -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import indextenbanner from "@/assets/json/indextenbanner.json";
import indextenservice from "@/assets/json/indextenservice.json";

export default {
  data() {
    return {
      indextenbanner: indextenbanner,
      indextenservice: indextenservice,
    };
  },

  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>
