// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../node_modules/vue-loader/dist/stylePostLoader.js!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[2]!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[3]!./assets/css/feather.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../node_modules/vue-loader/dist/stylePostLoader.js!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[2]!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[3]!./assets/css/custom.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "a{text-decoration:none}.floating_btn{position:fixed;bottom:60px;right:0;width:100px;height:100px;display:flex;flex-direction:column;align-items:center;justify-content:center;z-index:1000}@keyframes pulsing{to{box-shadow:0 0 0 15px rgba(232,76,61,0)}}.contact_icon{background-color:#42db87;color:#fff;width:60px;height:60px;font-size:30px;border-radius:50px;text-align:center;box-shadow:2px 2px 3px #999;display:flex;align-items:center;justify-content:center;transform:translatey(0);animation:pulse 2s infinite;box-shadow:0 0 0 0 #42db87;animation:pulsing 2s cubic-bezier(.66,0,0,1) infinite;font-weight:400;font-family:sans-serif;text-decoration:none!important;transition:all 1s ease-in-out}.text_icon{margin-top:8px;color:#707070;font-size:13px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
