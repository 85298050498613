<template>
  <div id="app">
      <router-view />
      <div class="floating_btn">
        <a style="text-decoration: none !important;" target="_blank" href="https://api.whatsapp.com/send?phone=5215514978294&text=Estoy%20interesado%20en%20sus%20servicios">
          <div class="contact_icon">
            <i style="text-decoration: none;" class="fab fa-whatsapp"></i>
          </div>
        </a>
      </div>
  </div>
</template>
<script>
import { loadStyles } from "@/loadStyles";
export default {
  setup() {
    loadStyles();
    return {};
  },
  name: "App",
};
</script>


<style>
@import "@/assets/css/feather.css";
@import "@/assets/css/custom.css";
a{
  text-decoration:none;
}
.floating_btn {
  position: fixed;
  bottom: 60px;
  right: 0px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  z-index: 1000;
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}

.contact_icon {
  background-color: #42db87;
  color: #fff;
  width: 60px;
  height: 60px;
  font-size:30px;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translatey(0px);
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 0 #42db87;
  -webkit-animation: pulsing 2s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 2s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 2s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 2s infinite cubic-bezier(0.66, 0, 0, 1);
  font-weight: normal;
  font-family: sans-serif;
  text-decoration: none !important;
  transition: all 1000ms ease-in-out;
}


.text_icon {
  margin-top: 8px;
  color: #707070;
  font-size: 13px;
}
</style>