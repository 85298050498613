<template>
  <div class="main-wrapper home-three">
    <layoutheader></layoutheader>
    <breadcrumb :title="info" :text="text" :text1="text1" />

    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-12">
              <div class="blog-view">
                <div class="blog blog-single-post">
                  <div class="blog-image">
                    <a href="javascript:void(0);"
                      ><img
                        alt="blog-image"
                        :src="require(`@/assets/img/Adolfo/servicios/${detalleServicio.Image}`)"
                        class="img-fluid"
                    /></a>
                  </div>
                  <h3 class="blog-title" style="font-size: 20px;">
                    {{ detalleServicio.Title }} <!-- - <span style="font-size: 15px;">{{ detalleServicio.descrp }}</span> -->
                  </h3>
                  <div class="blog-info clearfix">
                    <div class="post-left">
                      <ul>
                        <li>
                          <div class="post-author">
                            <img src="@/assets/img/doctors/doctor-thumb-02.jpg" alt="Post Author"/>
                            <span>Dr. José Adolfo Navarro Navarro</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="blog-content">
                    <p>
                      {{ detalleServicio.Informacion.Parrafo1 }}
                    </p>
                    <p>
                      {{ detalleServicio.Informacion.Parrafo2 }}
                    </p>
                    <p>
                      {{ detalleServicio.Informacion.Parrafo3 }}
                    </p>
                    <p>
                      {{ detalleServicio.Informacion.Parrafo4 }}
                    </p>
                    <p>
                      {{ detalleServicio.Informacion.Parrafo5 }}
                    </p>
                    <p>
                      {{ detalleServicio.Informacion.Parrafo6 }}
                    </p>
                    <p>
                      {{ detalleServicio.Informacion.Parrafo7 }}
                    </p>
                  </div>
                </div>

                <!-- <div class="card blog-share clearfix">
                  <div class="card-header">
                    <h4 class="card-title">Share the post</h4>
                  </div>
                  <div class="card-body">
                    <ul class="social-share">
                      <li>
                        <a href="javascript:;" title="Facebook"
                          ><i class="fab fa-facebook"></i
                        ></a>
                      </li>
                      <li>
                        <a href="javascript:;" title="Twitter"
                          ><i class="fab fa-twitter"></i
                        ></a>
                      </li>
                      <li>
                        <a href="javascript:;" title="Linkedin"
                          ><i class="fab fa-linkedin"></i
                        ></a>
                      </li>
                      <li>
                        <a href="javascript:;" title="Google Plus"
                          ><i class="fab fa-google-plus"></i
                        ></a>
                      </li>
                      <li>
                        <a href="javascript:;" title="Youtube"
                          ><i class="fab fa-youtube"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div> -->
                <!-- <div class="card author-widget clearfix">
                  <div class="card-header">
                    <h4 class="card-title">About Author</h4>
                  </div>
                  <div class="card-body">
                    <div class="about-author">
                      <div class="about-author-img">
                        <div class="author-img-wrap">
                          <router-link to="/doctor-profile"
                            ><img
                              class="img-fluid rounded-circle"
                              alt="Darren Elder"
                              src="@/assets/img/doctors/doctor-thumb-02.jpg"
                          /></router-link>
                        </div>
                      </div>
                      <div class="author-details">
                        <router-link
                          to="/doctor-profile"
                          class="blog-author-name"
                          >Dr. Darren Elder</router-link
                        >
                        <p class="mb-0">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> -->
                
                <!-- Blog Comments -->
                <!-- <div class="card blog-comments clearfix">
                  <div class="card-header">
                    <h4 class="card-title">Comentarios</h4>
                  </div>
                  <div class="card-body pb-0">
                    <ul class="comments-list">
                      <li>
                        <div class="comment">
                          <div class="comment-author">
                            <img
                              class="avatar"
                              alt="Michelle Fairfax"
                              src="@/assets/img/patients/patient4.jpg"
                            />
                          </div>
                          <div class="comment-block">
                            <span class="comment-by">
                              <span class="blog-author-name">Michelle Fairfax</span>
                            </span>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra
                              euismod odio, gravida pellentesque urna varius vitae, gravida pellentesque
                              urna varius vitae. Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit.
                            </p>
                            <p class="blog-date">Dec 6, 2023</p>
                            <a class="comment-btn" href="javascript:;">
                              <i class="fas fa-reply"></i> Reply
                            </a>
                          </div>
                        </div>
                        <ul class="comments-list reply">
                          <li>
                            <div class="comment">
                              <div class="comment-author">
                                <img
                                  class="avatar"
                                  alt="Gina Moore"
                                  src="@/assets/img/patients/patient5.jpg"
                                />
                              </div>
                              <div class="comment-block">
                                <span class="comment-by">
                                  <span class="blog-author-name">Gina Moore</span>
                                </span>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra
                                  euismod odio, gravida pellentesque urna varius vitae, gravida
                                  pellentesque urna varius vitae.
                                </p>
                                <p class="blog-date">Dec 6, 2023</p>
                                <a class="comment-btn" href="javascript:;">
                                  <i class="fas fa-reply"></i> Reply
                                </a>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="comment">
                              <div class="comment-author">
                                <img
                                  class="avatar"
                                  alt="Carl Kelly"
                                  src="@/assets/img/patients/patient3.jpg"
                                />
                              </div>
                              <div class="comment-block">
                                <span class="comment-by">
                                  <span class="blog-author-name">Carl Kelly</span>
                                </span>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra
                                  euismod odio, gravida pellentesque urna varius vitae, gravida
                                  pellentesque urna varius vitae.
                                </p>
                                <p class="blog-date">December 7, 2023</p>
                                <a class="comment-btn" href="javascript:;">
                                  <i class="fas fa-reply"></i> Reply
                                </a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div class="comment">
                          <div class="comment-author">
                            <img
                              class="avatar"
                              alt="Elsie Gilley"
                              src="@/assets/img/patients/patient6.jpg"
                            />
                          </div>
                          <div class="comment-block">
                            <span class="comment-by">
                              <span class="blog-author-name">Elsie Gilley</span>
                            </span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <p class="blog-date">December 11, 2023</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="comment">
                          <div class="comment-author">
                            <img
                              class="avatar"
                              alt="Joan Gardner"
                              src="@/assets/img/patients/patient7.jpg"
                            />
                          </div>
                          <div class="comment-block">
                            <span class="comment-by">
                              <span class="blog-author-name">Joan Gardner</span>
                            </span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <p class="blog-date">December 13, 2023</p>
                            <a class="comment-btn" href="javascript:;">
                              <i class="fas fa-reply"></i> Reply
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div> -->
                <!-- Blog Comments End -->

              </div>
            </div>

            <!-- Blog Sidebar -->
            <div class="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">
              <div class="stickysidebar">
                

                <!-- Latest Posts -->
                <div class="card post-widget">
                  <div class="card-header">
                    <h4 class="card-title">Más Fotos</h4>
                  </div>
                  <div class="card-body">
                    <ul class="latest-posts">
                      <li v-for="item in BlogSidebar" :key="item.id">
                        <div class="post-thumb">
                          <router-link to="/blog-details">
                            <img
                              class="img-fluid"
                              :src="require(`@/assets/img/blog/${item.Image}`)"
                              alt="blog-image"
                            />
                          </router-link>
                        </div>
                        <div class="post-info">
                          <h4>
                            <router-link to="">Imagen</router-link>
                          </h4>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- /Latest Posts -->

                <!-- {{ detalleServicio }} -->

                
              </div>
            </div>
            <!-- /Blog Sidebar -->
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
    <indexfooter></indexfooter>
    <scrolltotop />
  </div>
</template>

<script>
import BlogSidebar from "@/assets/json/blogsidebar.json";
import ServicesInfo from "@/assets/json/indexthreebanner.json";
export default {
  data() {
    return {
      text: "Home",
      text1: "Detalle del Servicio",
      BlogSidebar: BlogSidebar,
      headerBackground: "transparent",
      isPharmachyHeader: false,
      info: "",
      detalleServicio: {}
    };
  },
  created() {
      this.info = this.$route.params.info;
      this.detalleServicio = ServicesInfo.find(x => x.Title === this.info) 
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // Update the background color based on the scroll position
      this.headerBackground = scroll > 95 ? "#ffffff" : "transparent";

      // Check if the scroll position meets your condition to add the class
      this.isPharmachyHeader = scroll > 95; // Change 95 to your desired threshold
    },
  },
};
</script>
