<template>
  <div class="main-wrapper home-three">
    <layoutheader></layoutheader>
    <bannermaster title="Contáctame"/>

    <div class="page-wrapper">
      <!-- Contact Us -->
      <section class="contact-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-md-12">
              <div class="section-inner-header contact-inner-header">
                <h6>Ponte en contacto</h6>
                <h2>¿ Tienes alguna pregunta ?</h2>
              </div>
              <div class="card contact-card">
                <div class="card-body">
                  <div class="contact-icon">
                    <i class="feather-map-pin"></i>
                  </div>
                  <div class="contact-details">
                    <h4>Dirección</h4>
                    <p>HOSPITAL ÁNGELES DEL CARMEN TORRE ESPECIALIDADES MÉDICAS
                        TARASCOS 3473, Monraz, Guadalajara</p>
                  </div>
                </div>
              </div>
              <div class="card contact-card">
                <div class="card-body">
                  <div class="contact-icon">
                    <i class="feather-phone"></i>
                  </div>
                  <div class="contact-details">
                    <h4>Numero Telefonico</h4>
                    <p>+33 382 010 54</p>
                  </div>
                </div>
              </div>
              <div class="card contact-card">
                <div class="card-body">
                  <div class="contact-icon">
                    <i class="feather-mail"></i>
                  </div>
                  <div class="contact-details">
                    <h4>Dirección de Correo</h4>
                    <p>dr.anavarro@icloud.com</p>
                    <p>dr.joseadolfonavarronavarro@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-7 col-md-12 d-flex">
              <div class="card contact-form-card w-100">
                <div class="card-body">
                  <form action="javascript:;">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Nombre</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Ingresa tu nombre"
                            v-model="Nombre"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Dirección de correo</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Ingresa tu direccion de correo"
                            v-model="Correo"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Numero Telefonico</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Ingresa tu telefono"
                            v-model="Telefono"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Servicio que solicita</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Ingresa el Servicio"
                            v-model="Servicio"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="mb-2">Mensaje</label>
                          <textarea
                            class="form-control"
                            placeholder="Ingresa tus dudas, comentarios o información de lo que te gustaria saber"
                            v-model="Mensaje"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group-btn mb-0">
                          <b-button
                          @click="sendEmail()"
                            type="submit"
                            class="btn btn-primary prime-btn"
                            >Enviar Mensaje</b-button
                          >
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- /Contact Us -->

      <!-- Contact Map -->
      <div class="contact-map d-flex">
        <iframe
          src="https://www.google.com/maps/embed/v1/place?q=HOSPITAL+ÁNGELES+DEL+CARMEN+TORRE+ESPECIALIDADES+MÉDICAS+TARASCOS+3473,+Monraz,+Guadalajara&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <!-- /Contact Map -->
    </div>
    <indexfooter></indexfooter>
    <scrolltotop />
  </div>
</template>

<script>
import emailjs from 'emailjs-com';
export default {
  data() {
    return {
      title: "Contáctame ",
      text: "Home",
      text1: "Contacto",
      Nombre: "",
      Correo: "",
      Telefono: "",
      Servicio: "",
      Mensaje: "",
      mensajeError: ""
    };
  },
  methods: {
    sendEmail(e) {
      try {


        if(this.validarTodo()){
          emailjs.send('service_3hgjm8b', 'template_1j19m9f', 
          {
            from_name: this.Nombre,
            nombre_cliente: this.Nombre,
            correo_cliente: this.Correo,
            telefono_cliente: this.Telefono,
            servicio_problema: this.Servicio ?? "",
            message: this.Mensaje,
          }, '2tA0sexGSbqRt23en').then((response) => {
            this.$swal({
              title: "Enviado",
              text: "Mensaje Enviado con exito",
              icon: "success"
            });
            this.Nombre = "",
            this.Correo = "",
            this.Telefono = "",
            this.Servicio = "",
            this.Mensaje = "",
            this.mensajeError = ""
          })
        } else {
          this.$swal({
            icon: 'warning',
            title: 'Ups...',
            text: `${this.mensajeError}`,
          });
        }
        

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      /* this.name = ''
      this.email = ''
      this.message = '' */
    },
    validarTodo() {
        this.mensajeError = ''
        let good = false
        if (this.Nombre != ''){ this.mensajeError = this.mensajeError + '' } else { this.mensajeError = this.mensajeError + 'Falta el Nombre, ' }
        if (this.Correo != ''){ this.mensajeError = this.mensajeError + '' } else { this.mensajeError = this.mensajeError + 'Falta el correo, ' }
        if (this.Telefono){ this.mensajeError = this.mensajeError + '' } else { this.mensajeError = this.mensajeError + 'Falta el telefono, ' }
        if (this.Mensaje){ this.mensajeError = this.mensajeError + '' } else { this.mensajeError = this.mensajeError + 'Falta el mensaje, ' }
        if(this.mensajeError.length > 0){
            good = false
        } else {
            good = true
        }
        return good
    },
  }
};
</script>
