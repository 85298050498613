// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/Adolfo/banner/bg-banner.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main-wrapper.home-twelve .banner-section-fourteen.banner-section-twelve[data-v-5ee77e77]{background:linear-gradient(rgba(47,124,195,.5),rgba(47,124,195,.5)),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position:50%;background-color:#2f7cc3;padding:125px 0}.main-wrapper.home-twelve .services-section-fourteen .marquee-title h3[data-v-5ee77e77]{animation:marquee 15s linear infinite;background:linear-gradient(90deg,#0284c7 16.05%,#14ffeb);background-clip:text;-webkit-background-clip:text;-webkit-text-fill-color:transparent;font-size:50px;margin-bottom:0}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
