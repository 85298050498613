<template>
  <div class="setting-tab" style="background-color: white;">
    <div class="appointment-tabs">
      <ul class="nav">
        <li class="nav-item">
          <router-link class="nav-link" to="/curriculum-educacion"
            >Educación</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/curriculum-certificaciones"
            >Certificaciones</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>
