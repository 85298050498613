<template>
  <!-- Footer -->
  <footer class="footer-fourteen footer-twelve">
    <div class="footer-top">
      <div class="floating-bg">
        <img src="@/assets/img/bg/small-paw-2.png" alt="paw-image" />
        <img src="@/assets/img/bg/small-paw-3.png" alt="paw-image" />
        <img src="@/assets/img/bg/footer-round-bg.png" alt="paw-image" />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="footer-widget footer-about">
              <div class="footer-widget-title">
                <h4>About Doccure</h4>
              </div>
              <p>
                We can guide you through issues like Cardiac arrest, Heart
                Failure, Peripheral Artery Disease, Arrhythmia, Atrial
                Fibrillation, Cholesterol and High BP.
              </p>
              <div class="subscribe-form">
                <form action="javascript:void(0)">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email Address"
                  />
                  <button type="submit" class="btn">Subscribe</button>
                </form>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-3">
            <div class="footer-widget footer-menu">
              <div class="footer-widget-title">
                <h4>Useful Links</h4>
              </div>
              <ul>
                <li><router-link to="/index-7">Home</router-link></li>
                <li><router-link to="/doctor-dashboard">Doctors</router-link></li>
                <li><router-link to="/patient-dashboard">Patients</router-link></li>
                <li><router-link to="/pharmacy-index">Pharmacy</router-link></li>
                <li><a href="javascript:void(0);">Pages</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-3">
            <div class="footer-widget footer-menu">
              <div class="footer-widget-title">
                <h4>Services</h4>
              </div>
              <ul>
                <li><router-link to="/index-10">Obstetric care</router-link></li>
                <li><a href="javascript:void(0);">Prenatal care</a></li>
                <li><a href="javascript:void(0);">Ultrasound imaging</a></li>
                <li>
                  <a href="javascript:void(0);">Maternity and baby supplies</a>
                </li>
                <li>
                  <a href="javascript:void(0);">Counseling & Education</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-3">
            <div class="footer-widget footer-menu">
              <div class="footer-widget-title">
                <h4>Quick Links</h4>
              </div>
              <ul>
                <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                <li><router-link to="/terms-condition">Terms & Conditions</router-link></li>
                <li><router-link to="/privacy-policy">Refund Policy</router-link></li>
                <li><router-link to="/faq">FAQ</router-link></li>
                <li><router-link to="/blog-grid">Blogs</router-link></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-3">
            <div class="footer-widget footer-menu">
              <div class="footer-widget-title">
                <h4>Get In Touch</h4>
              </div>
              <ul>
                <li>
                  <span><i class="fa-solid fa-location-dot"></i>Address</span>
                  <p>123 Street Name, City, USA</p>
                </li>
                <li>
                  <span><i class="fa-solid fa-phone"></i>Phone Number</span>
                  <p>+1 315 369 5943</p>
                </li>
                <li>
                  <span
                    ><i class="fa-regular fa-envelope"></i>Email Address</span
                  >
                  <p>doccure@example.com</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row align-items-center">
            <div class="col-md-6 col-lg-6">
              <div class="copyright-text">
                <p class="mb-0">
                  Copyright © {{ new Date().getFullYear() }} Doccure. All Rights Reserved
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <!-- Social Links -->
              <ul class="social-links">
                <li>
                  <a href="#"><i class="fa-brands fa-facebook"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa-brands fa-instagram"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa-brands fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa-brands fa-linkedin"></i></a>
                </li>
              </ul>
              <!-- /Social Links -->
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
  </footer>
  <!-- /Footer -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>
