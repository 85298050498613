<template>
  <div class="stickysidebar">
  <div class="profile-sidebar patient-sidebar profile-sidebar-new">
    <div class="widget-profile pro-widget-content">
      <div class="profile-info-widget">
        <router-link to="/profile-settings" class="booking-doc-img">
          <img
            src="@/assets/img/doctors-dashboard/profile-06.jpg"
            alt="User Image"
          />
        </router-link>
        <div class="profile-det-info">
          <h3><router-link to="/profile-settings">Hendrita</router-link></h3>
          <div class="patient-details">
            <h5 class="mb-0">Patient ID : PT254654</h5>
          </div>
          <span
            >Female <i class="fa-solid fa-circle"></i> 32 years 03 Months</span
          >
        </div>
      </div>
    </div>
    <div class="dashboard-widget">
      <nav class="dashboard-menu">
        <ul>
          <li :class="{ active: isActive('/patient-dashboard') }">
            <router-link to="/patient-dashboard">
              <i class="fa-solid fa-shapes"></i>
              <span>Dashboard</span>
            </router-link>
          </li>
          <li :class="{ active: isActive(['/patient-appointments', '/patient-upcoming-appointment', '/patient-cancelled-appointment', '/patient-completed-appointment']) }">
            <router-link to="/patient-appointments">
              <i class="fa-solid fa-calendar-days"></i>
              <span>My Appointments</span>
            </router-link>
          </li>
          <li :class="{ active: isActive('/favourites') }">
            <router-link to="/favourites">
              <i class="fa-solid fa-user-doctor"></i>
              <span>Favourites</span>
            </router-link>
          </li>
          <li :class="{ active: isActive('/dependent') }">
            <router-link to="/dependent">
              <i class="fa-solid fa-user-plus"></i>
              <span>Dependants</span>
            </router-link>
          </li>
          <li :class="{ active: isActive('/medical-records') }">
            <router-link to="/medical-records">
              <i class="fa-solid fa-money-bill-1"></i>
              <span>Add Medical Records</span>
            </router-link>
          </li>
          <li :class="{ active: isActive('/patient-accounts') }">
            <router-link to="/patient-accounts">
              <i class="fa-solid fa-file-contract"></i>
              <span>Accounts</span>
            </router-link>
          </li>
          <li :class="{ active: isActive('/patient-invoices') }">
            <router-link to="/patient-invoices">
              <i class="fa-solid fa-file-lines"></i>
              <span>Invoices</span>
            </router-link>
          </li>
          <li :class="{ active: isActive('/chat') }">
            <router-link to="/chat">
              <i class="fa-solid fa-comments"></i>
              <span>Message</span>
              <small class="unread-msg">7</small>
            </router-link>
          </li>
          <li :class="{ active: isActive('/profile-settings') }">
            <router-link to="/profile-settings">
              <i class="fa-solid fa-user-pen"></i>
              <span>Profile Settings</span>
            </router-link>
          </li>
          <li :class="{ active: isActive('/medical-details') }">
            <router-link to="/medical-details">
              <i class="fa-solid fa-shield-halved"></i>
              <span>Medical Details</span>
            </router-link>
          </li>
          <li :class="{ active: isActive('/change-password') }">
            <router-link to="/change-password">
              <i class="fa-solid fa-key"></i>
              <span>Change Password</span>
            </router-link>
          </li>
          <li>
            <router-link to="/login">
              <i class="fa-solid fa-calendar-check"></i>
              <span>Logout</span>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      activeClass: "active",
    };
  },
  methods: {
    isActive(paths) {
      return paths.includes(this.$route.path);
    },
  },
};
</script>
