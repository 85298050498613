<template>
    <!-- Appointment Cancel Reason Modal -->
    <div class="modal fade custom-modal custom-modal-two" id="reject_reason">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Reject Reason</h5>
                    <button type="button" data-bs-dismiss="modal" aria-label="Close">
                        <span><i class="fa-solid fa-x"></i></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="reason-of-rejection">
                        <p>I have an urgent surgery, while our Appointment so i am rejecting appointment, you can book
                            an reschedule by next week.</p>
                        <span class="text-danger">Cancelled By You on 23 March 2023</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Appointment Cancel Reason Modal -->
</template>