<template>
  <!-- Footer -->
  <footer class="footer footer-three footer-fourteen">
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-4">
            <div class="footer-widget footer-about">
              <div class="footer-logo">
                <router-link to="/index-10"
                  ><img src="@/assets/img/footer-logo.png" alt="logo"
                /></router-link>
              </div>
              <div class="footer-about-content">
                <p>
                  Effortlessly schedule your medical appointments with Doccure.
                  Connect with healthcare professionals, manage appointments &
                  prioritize your well being
                </p>
              </div>
              <div class="social-icon">
                <ul>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fab fa-facebook"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fab fa-instagram"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fab fa-twitter"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fab fa-linkedin-in"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-4">
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Useful Links</h2>
              <ul>
                <li><a href="javascript:void(0);">Home</a></li>
                <li><a href="javascript:void(0);">Doctors</a></li>
                <li><a href="javascript:void(0);">Patients</a></li>
                <li><a href="javascript:void(0);">Pharmacy</a></li>
                <li><a href="javascript:void(0);">Pages</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-4">
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Services</h2>
              <ul>
                <li><a href="javascript:void(0);">Obstetric care</a></li>
                <li><a href="javascript:void(0);">Terms & Conditions</a></li>
                <li><a href="javascript:void(0);">Refund Policy</a></li>
                <li><a href="javascript:void(0);">FAQ</a></li>
                <li><a href="javascript:void(0);">Blogs</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-4">
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Get In Touch</h2>
              <ul>
                <li><a href="javascript:void(0);">Privacy Policy</a></li>
                <li><a href="javascript:void(0);">Prenatal care</a></li>
                <li><a href="javascript:void(0);">Ultrasound imaging</a></li>
                <li>
                  <a href="javascript:void(0);">Maternity and baby supplies</a>
                </li>
                <li>
                  <a href="javascript:void(0);">Counseling & Education</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-4">
            <div class="footer-widget footer-contact">
              <h2 class="footer-title">Contact Us</h2>
              <div class="footer-contact-info">
                <div class="footer-address">
                  <p class="loc-info"><i class="feather-map-pin"></i>Address</p>
                  <p>123 Street Name, City, USA</p>
                </div>
                <div class="footer-address">
                  <p class="loc-info">
                    <i class="feather-phone-call"></i>Phone Number
                  </p>
                  <p>+1 315 369 5943</p>
                </div>
                <div class="footer-address mb-0">
                  <p class="loc-info">
                    <i class="feather-mail"></i>Email Address
                  </p>
                  <p>doccure@example.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-12">
              <div class="copyright-text text-center">
                <p class="mb-0">Copyright © {{ new Date().getFullYear() }} All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
  </footer>
  <!-- /Footer -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>
