<template>
  <!-- Feedback -->
  <section class="clients-section-fourteen">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="client-inner-main">
            <img
              src="@/assets/img/Adolfo/inicio/image10_preview_rev_1.png"
              alt="image"
              class="img-fluid imgShowDoc2"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="section-header-fourteen">
            <h2>Pacientes<span style=""> Felices</span></h2>
            <h4>Comentarios<span></span></h4>
            <h6>Lo que mis clientes opinan de mi</h6>
          </div>
          <div
            class="owl-carousel feedback-slider-fourteen owl-theme aos cardRatingShow"
            data-aos="fade-up"
          >
            <Carousel
              :wrap-around="true"
              :settings="settings"
              :breakpoints="breakpoints"
            >
              <Slide v-for="record in IndexNineFeedback" :key="record.id">
                <div class="card feedback-card">
                  <div class="card-body feedback-card-body text-start">
                    <div class="feedback-inner-main">
                      <div class="rating rating-fourteen">
                        
                        <i v-for="(ss, index) in record.Stars" :key="index" class="fas fa-star filled me-1"></i>
                      </div>
                      <p>
                        {{ record.Content }}
                      </p>
                      <h4>{{ record.Name }}</h4>
                      <h6>{{ record.fecha }}</h6>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Feedback -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import IndexNineFeedback from "@/assets/json/indexninefeedback.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      IndexNineFeedback: IndexNineFeedback,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>

<style scoped>
.section-header-fourteen h2 span::before {
  content: none !important;
}


@media (max-width: 1199px) {
  
  .client-inner-main {
    display: block !important;
    position: absolute;
    z-index: 1;
    margin-left: -100px;
    margin-top: 30px;
  }
}

@media (max-width: 992px) {
  .imgShowDoc2{
    height: 150px !important;
  }

  .client-inner-main {
    display: block !important;
    position: absolute;
    z-index: 1;
    margin-left: -100px;
    margin-top: 30px;
  }
}

@media (max-width: 920px) {
  
  .imgShowDoc2{
    height: 100px !important;
  }

  .client-inner-main {
    display: block !important;
    position: absolute;
    z-index: 1;
    margin-left: -60px;
    margin-top: 60px;
  }

}

@media (max-width: 660px) {

  .cardRatingShow {
    padding-top: 90px;
  }

  .imgShowDoc2{
    height: 130px !important;
  }

  .client-inner-main {
    display: block !important;
    position: absolute;
    z-index: 1;
    margin-left: 150px;
    margin-top: 140px;
  }
}
</style>
