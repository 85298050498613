<template>
<section class="section seller-section">
    <div class="container">
      <div class="seller-info">
        <div class="row">
        <div class="col-md-6 aos" data-aos="fade-up">
          <div class="section-heading">
            <h2>Enfermedades que trato</h2>
            <p>
              Tratamiento de una amplia variedad de enfermedades.
            </p>
          </div>
        </div>
        <div class="col-md-6 text-end aos" data-aos="fade-up">
          <div class="owl-nav slide-nav-1 text-end nav-control"></div>
        </div>
      </div>
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="seller-grid" v-for="(enfermedad, index) in Enfermedades1" :key="index">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img :src="require(`@/assets/img/Adolfo/enfermedades/${enfermedad.Imagen}`)" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>{{ enfermedad.Titulo }}</h6>
                <!-- <p>Descripcion chiquita</p> -->
              </div>
            </div>            
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="seller-grid" v-for="(enfermedad2, index) in Enfermedades2" :key="index">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img :src="require(`@/assets/img/Adolfo/enfermedades/${enfermedad2.Imagen}`)" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>{{ enfermedad2.Titulo }}</h6>
                <!-- <p>Descripcion chiquita</p> -->
              </div>
            </div>            
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="seller-grid" v-for="(enfermedad3, index) in Enfermedades3" :key="index">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img :src="require(`@/assets/img/Adolfo/enfermedades/${enfermedad3.Imagen}`)" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>{{ enfermedad3.Titulo }}</h6>
                <!-- <p>Descripcion chiquita</p> -->
              </div>
            </div>            
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="seller-grid" v-for="(enfermedad4, index) in Enfermedades4" :key="index">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img :src="require(`@/assets/img/Adolfo/enfermedades/${enfermedad4.Imagen}`)" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>{{ enfermedad4.Titulo }}</h6>
              </div>
            </div>            
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="seller-grid" v-for="(enfermedad5, index) in Enfermedades5" :key="index">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img :src="require(`@/assets/img/Adolfo/enfermedades/${enfermedad5.Imagen}`)" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>{{ enfermedad5.Titulo }}</h6>
                <!-- <p>Descripcion chiquita</p> -->
              </div>
            </div>            
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="seller-grid" v-for="(enfermedad6, index) in Enfermedades6" :key="index">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img :src="require(`@/assets/img/Adolfo/enfermedades/${enfermedad6.Imagen}`)" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>{{ enfermedad6.Titulo }}</h6>
                <!-- <p>Descripcion chiquita</p> -->
              </div>
            </div>            
          </div>
         
        </div>
      </div>
    </div>
  </section>
  <!-- Work Section -->
  <!-- <section class="work-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12 work-img-info aos" data-aos="fade-up">
          <div class="work-img">
            <img src="@/assets/img/work-img.png" class="img-fluid" alt="Image" />
          </div>
        </div>
        <div class="col-lg-8 col-md-12 work-details">
          <div class="section-header-one aos" data-aos="fade-up">
            <h5>How it Works</h5>
            <h2 class="section-title">4 easy steps to get your solution</h2>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 aos" data-aos="fade-up">
              <div class="work-info">
                <div class="work-icon">
                  <span><img src="@/assets/img/icons/work-01.svg" alt="Image" /></span>
                </div>
                <div class="work-content">
                  <h5>Search Doctor</h5>
                  <p>
                    Lorem ipsum dolor consectetur adipiscing elit, tempor incididunt
                    labore dolore magna aliqua.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 aos" data-aos="fade-up">
              <div class="work-info">
                <div class="work-icon">
                  <span><img src="@/assets/img/icons/work-02.svg" alt="Image" /></span>
                </div>
                <div class="work-content">
                  <h5>Check Doctor Profile</h5>
                  <p>
                    Lorem ipsum dolor consectetur adipiscing elit, tempor incididunt
                    labore dolore magna aliqua.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 aos" data-aos="fade-up">
              <div class="work-info">
                <div class="work-icon">
                  <span><img src="@/assets/img/icons/work-03.svg" alt="Image" /></span>
                </div>
                <div class="work-content">
                  <h5>Schedule Appointment</h5>
                  <p>
                    Lorem ipsum dolor consectetur adipiscing elit, tempor incididunt
                    labore dolore magna aliqua.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 aos" data-aos="fade-up">
              <div class="work-info">
                <div class="work-icon">
                  <span><img src="@/assets/img/icons/work-04.svg" alt="Image" /></span>
                </div>
                <div class="work-content">
                  <h5>Get Your Solution</h5>
                  <p>
                    Lorem ipsum dolor consectetur adipiscing elit, tempor incididunt
                    labore dolore magna aliqua.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- /Work Section -->

  <!-- Articles Section -->
  <div></div>
  <!-- <section class="articles-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 aos" data-aos="fade-up">
          <div class="section-header-one text-center">
            <h2 class="section-title">Mis Conferencias</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-6 col-md-6 d-flex aos"
          data-aos="fade-up"
          v-for="item in IndexArticles"
          :key="item.id"
        >
          <div class="articles-grid w-100">
            <div class="articles-info">
              <div class="articles-left">
                <router-link to="/blog-details">
                  <div class="articles-img">
                    <img
                      @click="index = imageIndex"
                      :src="require(`@/assets/img/blog/${item.Image}`)"
                      class="img-fluid"
                      alt="Image"
                    />
                  </div>
                </router-link>
              </div>
              <div class="articles-right">
                <div class="articles-content">
                  <ul class="articles-list nav">
                    <li><i class="feather-user"></i> {{ item.Name }}</li>
                    <li><i class="feather-calendar"></i> {{ item.Day }}</li>
                  </ul>
                  <h4>
                    <router-link to="/blog-details">{{ item.Title }}</router-link>
                  </h4>
                  <p>{{ item.Content }}</p>
                  <router-link to="/blog-details" class="btn">Read More</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </section> -->
  <!-- /Articles Section -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";

import IndexArticles from "@/assets/json/indexarticles.json";
export default {
  
  data() {
    return {
      IndexArticles: IndexArticles,
      Enfermedades1: [
        { Titulo: "Hemorroides" , Imagen: "hemorroides.png" },
        { Titulo: "hernias inguinales" , Imagen: "hernia-inguinal.png"},
        { Titulo:"hernia umbilicales", Imagen: "hernia-umbilical.png"},
        { Titulo: "Hernias pared abdominal", Imagen: "hernia-abdominal.png"},
        { Titulo: "Apendicitis", Imagen: "apendicitis.png"},
      ],
      Enfermedades2: [
        { Titulo: "Diverticulitis" , Imagen: "diverticulitis.png" },
        { Titulo: "Vigilancia de estomas" , Imagen: "estomas.png"},
        { Titulo: "Cierres de las bolsas de estomas" , Imagen: "estomas.png"},
        { Titulo:"Reconexiones intestinales", Imagen: "Reconexiones intestinales.png"},
        { Titulo: "Colonoscopías para detección de pólipos y cáncer", Imagen: "cancer-de-colon.png"},
      ],
      Enfermedades3: [
        { Titulo: "Sangrado Intestinal y Anal" , Imagen: "sangrado intestinal.png" },
        { Titulo: "Prurito anal" , Imagen: "prurito-anal.png"},
        { Titulo: "Condilomas anales" , Imagen: "condiloma.png"},
        { Titulo:"Quistes pilonidales", Imagen: "quiste.png"},
        { Titulo: "Incontinencia Fecal", Imagen: "incontingencia-fecal.png"},
      ],
      Enfermedades4: [
        { Titulo: "Prolapso rectal" , Imagen: "prolapso-rectal.png" },
        { Titulo: "Fisuras anales" , Imagen: "fisura-anal.png"},
        { Titulo: "Abscesos y fístulas anales" , Imagen: "absecos anorrectales.png"},
        
      ],
      Enfermedades5: [
        { Titulo: "Enfermedad inflamatoria intestinal (CUCI y Crohn)" , Imagen: "intestino-inflamacion.png" },
        { Titulo: "Fistulas rectovaginales" , Imagen: "recto-vaginal.png"},
        { Titulo: "Anoscopías en consultorio" , Imagen: "urgencias quirúrgicas.png"},
      ],
      Enfermedades6: [
        { Titulo:"Estreñimiento crónico", Imagen: "fístulas anales.png"},
        { Titulo: "Colitis", Imagen: "colitis.png"},
        { Titulo:"Dolor anal y rectal", Imagen: "dolor-anal.png"},
        { Titulo:"Piedras Vesicula", Imagen:"piedras vesicula.png"}
      ]
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>
