<template>
  <div class="tab-pane fade" id="pat_refundrequest">
    <div class="custom-new-table">
      <div class="table-responsive">
        <table class="table table-hover table-center mb-0">
          <thead>
            <tr>
              <th>ID</th>
              <th>Patient</th>
              <th>Appointment Date</th>
              <th>Payment</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a href="#"><span class="text-blue">#AC-1234</span></a>
              </td>
              <td>
                <div class="table-avtatar">
                  <router-link to="/patient-profile"
                    ><img
                      src="@/assets/img/doctors-dashboard/doctor-profile-img.jpg"
                      alt=""
                    />Edalin Hendry</router-link
                  >
                </div>
              </td>
              <td>24 Mar 2024 - 10:30 AM</td>
              <td>$300</td>
              <td>
                <span class="badge badge-success-bg">Paid</span>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <a href="" class="account-action me-2"
                    ><i class="fa-solid fa-link"></i
                  ></a>
                  <a href="" class="account-action text-success me-2"
                    ><i class="fa-solid fa-check"></i
                  ></a>
                  <a href="" class="account-action text-danger me-2"
                    ><i class="fa-solid fa-x"></i
                  ></a>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <a href="#"><span class="text-blue">#AC-1235</span></a>
              </td>
              <td>
                <div class="table-avtatar">
                  <router-link to="/patient-profile"
                    ><img
                      src="@/assets/img/doctors/doctor-thumb-01.jpg"
                      alt=""
                    />Shanta Neill</router-link
                  >
                </div>
              </td>
              <td>28 Mar 2024 - 11:15 AM</td>
              <td>$250</td>
              <td>
                <span class="badge badge-success-bg">Paid</span>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <a href="" class="account-action me-2"
                    ><i class="fa-solid fa-link"></i
                  ></a>
                  <a href="" class="account-action text-success me-2"
                    ><i class="fa-solid fa-check"></i
                  ></a>
                  <a href="" class="account-action text-danger me-2"
                    ><i class="fa-solid fa-x"></i
                  ></a>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <a href="#"><span class="text-blue">#AC-1236</span></a>
              </td>
              <td>
                <div class="table-avtatar">
                  <router-link to="/patient-profile"
                    ><img
                      src="@/assets/img/doctors/doctor-thumb-02.jpg"
                      alt=""
                    />Anthony Tran</router-link
                  >
                </div>
              </td>
              <td>02 Apr 2024 - 04:15 PM</td>
              <td>$380</td>
              <td>
                <span class="badge badge-success-bg">Paid</span>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <a href="" class="account-action me-2"
                    ><i class="fa-solid fa-link"></i
                  ></a>
                  <a href="" class="account-action text-success me-2"
                    ><i class="fa-solid fa-check"></i
                  ></a>
                  <a href="" class="account-action text-danger me-2"
                    ><i class="fa-solid fa-x"></i
                  ></a>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <a href="#"><span class="text-blue">#AC-1237</span></a>
              </td>
              <td>
                <div class="table-avtatar">
                  <router-link to="/patient-profile"
                    ><img
                      src="@/assets/img/doctors/doctor-thumb-03.jpg"
                      alt=""
                    />Susan Lingo</router-link
                  >
                </div>
              </td>
              <td>13 Apr 2024 - 05:30 PM</td>
              <td>$400</td>
              <td>
                <span class="badge badge-warning-bg">Pending</span>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <a href="" class="account-action me-2"
                    ><i class="fa-solid fa-link"></i
                  ></a>
                  <a href="" class="account-action text-success me-2"
                    ><i class="fa-solid fa-check"></i
                  ></a>
                  <a href="" class="account-action text-danger me-2"
                    ><i class="fa-solid fa-x"></i
                  ></a>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <a href="#"><span class="text-blue">#AC-1238</span></a>
              </td>
              <td>
                <div class="table-avtatar">
                  <router-link to="/patient-profile"
                    ><img
                      src="@/assets/img/doctors/doctor-thumb-08.jpg"
                      alt=""
                    />Joseph Boyd</router-link
                  >
                </div>
              </td>
              <td>21 Apr 2024 - 12:30 PM</td>
              <td>$320</td>
              <td>
                <span class="badge badge-success-bg">Paid</span>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <a href="" class="account-action me-2"
                    ><i class="fa-solid fa-link"></i
                  ></a>
                  <a href="" class="account-action text-success me-2"
                    ><i class="fa-solid fa-check"></i
                  ></a>
                  <a href="" class="account-action text-danger me-2"
                    ><i class="fa-solid fa-x"></i
                  ></a>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <a href="#"><span class="text-blue">#AC-1239</span></a>
              </td>
              <td>
                <div class="table-avtatar">
                  <router-link to="/patient-profile"
                    ><img
                      src="@/assets/img/doctors/doctor-thumb-06.jpg"
                      alt=""
                    />Juliet Grabriel</router-link
                  >
                </div>
              </td>
              <td>17 May 2024 - 09:20 AM</td>
              <td>$280</td>
              <td>
                <span class="badge badge-success-bg">Paid</span>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <a href="" class="account-action me-2"
                    ><i class="fa-solid fa-link"></i
                  ></a>
                  <a href="" class="account-action text-success me-2"
                    ><i class="fa-solid fa-check"></i
                  ></a>
                  <a href="" class="account-action text-danger me-2"
                    ><i class="fa-solid fa-x"></i
                  ></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Pagination -->
    <div class="pagination dashboard-pagination">
      <ul>
        <li>
          <a href="" class="page-link"
            ><i class="fa-solid fa-chevron-left"></i
          ></a>
        </li>
        <li>
          <a href="" class="page-link">1</a>
        </li>
        <li>
          <a href="" class="page-link active">2</a>
        </li>
        <li>
          <a href="" class="page-link">3</a>
        </li>
        <li>
          <a href="" class="page-link">4</a>
        </li>
        <li>
          <a href="" class="page-link">...</a>
        </li>
        <li>
          <a href="" class="page-link"
            ><i class="fa-solid fa-chevron-right"></i
          ></a>
        </li>
      </ul>
    </div>
    <!-- /Pagination -->
  </div>
</template>
