<template>
  <div class="main-wrapper home-twelve">
    <layoutheader></layoutheader>
    <indexsevenbanner></indexsevenbanner>
    <indexsevenfeedback2></indexsevenfeedback2>
    
    <!-- <index-seven-frequently></index-seven-frequently> -->
    <!-- <indexsevenfooter></indexsevenfooter> -->
    <indexfooter></indexfooter>
    <cursor />
  </div>
  <scrolltotop />
</template>

<script>
export default {
  data() {
    return {
      headerBackground: "transparent",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scroll > 95) {
        this.headerBackground = "#fff";
        this.headerClass = "pharmacy-header"
      } else {
        this.headerBackground = "transparent";
        this.headerClass = ""
      }
    },
  },
};
</script>
