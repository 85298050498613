<template>
  <div class="modal fade" id="delete_modal" aria-hidden="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-content p-2">
            <h4 class="modal-title">Delete</h4>
            <p class="mb-4">Are you sure want to delete?</p>
            <b-button type="button" class="btn btn-primary me-2">Save </b-button>
            <b-button type="button" variant="danger" data-bs-dismiss="modal"
              >Close</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
