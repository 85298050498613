<template>
  <div
    class="tab-pane fade show active"
    id="pills-upcoming"
    role="tabpanel"
    aria-labelledby="pills-upcoming-tab"
  >
    <!-- Appointment List -->
    <div
      class="appointment-wrap"
      v-for="item in Patients_Upcoming"
      :key="item.id"
    >
      <ul>
        <li>
          <div class="patinet-information">
            <router-link to="/patient-upcoming-appointment">
              <img
                :src="require(`@/assets/img/doctors/${item.Image}`)"
                alt="User Image"
              />
            </router-link>
            <div class="patient-info">
              <p>{{ item.Id }}</p>
              <h6>
                <router-link to="/patient-upcoming-appointment">{{
                  item.Name
                }}</router-link
                ><span class="badge new-tag">{{ item.Experience }}</span>
              </h6>
            </div>
          </div>
        </li>
        <li class="appointment-info">
          <p><i class="fa-solid fa-clock"></i>{{ item.Date }}</p>
          <ul class="d-flex apponitment-types">
            <li>General Visit</li>
            <li>{{ item.Call }}</li>
          </ul>
        </li>
        <li class="mail-info-patient">
          <ul>
            <li><i class="fa-solid fa-envelope"></i>{{ item.Email }}</li>
            <li><i class="fa-solid fa-phone"></i>{{ item.Phone }}</li>
          </ul>
        </li>
        <li class="appointment-action">
          <ul>
            <li>
              <router-link to="/patient-upcoming-appointment"
                ><i class="fa-solid fa-eye"></i
              ></router-link>
            </li>
            <li>
              <a href="#"><i class="fa-solid fa-comments"></i></a>
            </li>
            <li>
              <a href="#"><i class="fa-solid fa-xmark"></i></a>
            </li>
          </ul>
        </li>
        <li class="appointment-detail-btn">
          <a href="#" class="start-link"
            ><i class="fa-solid fa-calendar-check me-1"></i>Attend</a
          >
        </li>
      </ul>
    </div>
    <!-- /Appointment List -->

    <!-- Pagination -->
    <div class="pagination dashboard-pagination">
      <ul>
        <li>
          <a href="#" class="page-link"
            ><i class="fa-solid fa-chevron-left"></i
          ></a>
        </li>
        <li>
          <a href="#" class="page-link">1</a>
        </li>
        <li>
          <a href="#" class="page-link active">2</a>
        </li>
        <li>
          <a href="#" class="page-link">3</a>
        </li>
        <li>
          <a href="#" class="page-link">4</a>
        </li>
        <li>
          <a href="#" class="page-link">...</a>
        </li>
        <li>
          <a href="#" class="page-link"
            ><i class="fa-solid fa-chevron-right"></i
          ></a>
        </li>
      </ul>
    </div>
    <!-- /Pagination -->
  </div>
</template>
<script>
import Patients_Upcoming from "@/assets/json/patients-upcoming.json";
export default {
  data() {
    return {
      Patients_Upcoming: Patients_Upcoming,
    };
  },
};
</script>
