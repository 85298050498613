<template>
  <!-- Home Banner -->
  <section class="doctor-search-section" style="padding-top: 150px;">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 aos" data-aos="fade-up">
          <div class="doctor-search">
            <div class="banner-header">
              <h2><span>Dr.</span>José Adolfo Navarro Navarro</h2>
              <p>
                
                <b>Cirujano General y Colo-Proctólogo</b>
                <br>
                Cirugía laparoscópica y robótica
              </p>
            </div>
            <!-- <form @submit.prevent="submitForm" class="form-block d-flex">
              
             <div class="clinic-booking">
                  <a target="_blank" class="apt-btn" href="https://www.doctoralia.com.mx/jose-adolfo-navarro-navarro/proctologo-cirujano-general/guadalajara"
                    > Agendar una Cita</a
                  >
                </div>
            </form> -->
          </div>
        </div>
        <div class="col-lg-5 aos" data-aos="fade-up" style="margin-top: 50px; margin-bottom: 80px;">
          <img
            src="@/assets/img/Adolfo/inicio/image5_preview_rev_1.png"
            class="img-fluid dr-img showImgDoc1"
            alt="doctor-image"
          />
        </div>
      </div>
    </div>
  </section>
  <!-- /Home Banner -->

  <!-- Clinic Section -->
  <section class="clinics-section">
    <div class="shapes">
      <img
        src="@/assets/img/shapes/shape-1.png"
        alt="Image"
        class="img-fluid shape-1"
      />
      <img
        src="@/assets/img/shapes/shape-2.png"
        alt="Image"
        class="img-fluid shape-2"
      />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6 aos" data-aos="fade-up">
          <div class="section-heading">
            <h2>Servicios y Especialidades</h2>
            <!-- Experience Details -->
        <div class="widget experience-widget">
          <h4 class="widget-title">Conoce mis especialidades :</h4>
          <div class="experience-box">
            <ul class="experience-list">
              <li>
                <div class="experience-user">
                  <div class="before-circle"></div>
                </div>
                <div class="experience-content">
                  <div class="timeline-content">
                    <a href="#/" style="text-decoration: none;" class="name">Cirujano experto</a>
                  </div>
                </div>
              </li>
              <li>
                <div class="experience-user">
                  <div class="before-circle"></div>
                </div>
                <div class="experience-content">
                  <div class="timeline-content">
                    <a href="#/" style="text-decoration: none;" class="name">Manejo de tecnologías avanzadas</a>
                  </div>
                </div>
              </li>
              <li>
                <div class="experience-user">
                  <div class="before-circle"></div>
                </div>
                <div class="experience-content">
                  <div class="timeline-content">
                    <a href="#/" style="text-decoration: none;" class="name">Instalaciones quirúrgicas de alta calidad</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- /Experience Details -->
          </div>
        </div>
        <div class="col-md-6 text-end aos" data-aos="fade-up">
          <div class="owl-nav slide-nav-1 text-end nav-control"></div>
        </div>
      </div>
      <div class="owl-carousel clinics owl-theme">
        <Carousel
          :wrap-around="true"
          :settings="settings"
          :breakpoints="breakpoints"
        >
          <Slide v-for="item in indexThreeBanner" :key="item.id">
            
            <div class="item">
              <div @click="sendInfoServicesDetails(item)">
                <div class="clinic-item">
                  <div class="clinics-card">
                    <div class="clinics-img" style="height: 250px;">
                      <img
                        :src="require(`@/assets/img/Adolfo/servicios/${item.Image}`)"
                        alt="Image"
                        style="height: 250px; object-fit: cover;"
                      />
                    </div>
                    <div class="clinics-info">
                      <span class="clinic-img">
                        <img
                          :src="require(`@/assets/img/Adolfo/iconos/${item.Logo}`)"
                          alt="kidney-image"
                          class="img-fluid"
                          style="width: 40px !important;"
                        />
                      </span>
                      <a href="#" style="text-decoration: none !important;"
                        ><span>{{ item.Title }}</span></a
                      >
                    </div>
                  </div>
                  <div class="clinics-icon">
                    <a href="javascript:;"
                      ><i class="fas fa-long-arrow-alt-right"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>
  </section>
  <!-- /Clinic Section -->
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexThreeBanner from "@/assets/json/indexthreebanner.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      CardSel: ["Select Department", "Cardiology", "Neurology", "Urology"],
      indexThreeBanner: indexThreeBanner,
      settings: {
        itemsToShow: 5,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 5,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 5,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 5,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 5,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  methods: {
    sendInfoServicesDetails(info) {
      console.log(info);
      this.$router.push({ path: `/detalle-servicios/${info.Title}`, params: info })
    }
  }
};
</script>

<style scoped>
@media (max-width: 992px) {
  .showImgDoc1{
    display: block;
    position: absolute;
    margin-top: -260px;
    height: 246px;
    right: 0px;
    margin-right: -40px;
  }
}

@media (max-width: 767px) {
  .showImgDoc1{
    display: block;
    position: absolute;
    margin-top: -140px;
    height: 180px;
    right: 0px;
    margin-right: -20px;
  }
}

@media (max-width: 550px) {
  .showImgDoc1{
    display: flex;
    position: relative;
    margin-top: -25px;
    height: 180px;
    right: 0px;
    margin-right: 0px;
    margin-left: 40px;
    justify-content: center;
  }
}
</style>
