<template>
  <!-- Footer -->
  <footer class="footer footer-thirteen">
    <div class="footer-bg-icon">
      <img
        src="@/assets/img/bg/footer-bg.png"
        alt="Rainbow"
        class="aos"
        data-aos="fade-left"
      />
    </div>
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-md-6">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">About us</h2>
                  <ul>
                    <li>
                      <a href="javascript:void(0);">Our Doctors</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Why us</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">How it works</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Our News & Events</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">FAQ</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">Our cabins</h2>
                  <ul>
                    <li>
                      <a href="javascript:void(0);">North of London</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Golden Hideaway</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Oak Treehouse</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Acacia Retreat</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Blue Lagoon</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="row">
              <div class="col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">Services</h2>
                  <ul>
                    <li>
                      <a href="javascript:void(0);">Prenatal/New-born</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Vaccinations</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Blood tests</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Diagnostic tests</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Parents training classes</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4">
                <div class="footer-widget footer-menu footer-menu-thirteen">
                  <h2 class="footer-title">Services</h2>
                  <ul>
                    <li>
                      <a href="javascript:void(0);">Our Doctors</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Why us</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">How it works</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Our News & Events</a>
                    </li>
                    <li>
                      <router-link to="/faq">FAQ</router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">Support</h2>
                  <ul>
                    <li>
                      <a href="javascript:void(0);">Help Us</a>
                    </li>
                    <li>
                      <router-link to="/privacy-policy"
                        >Privacy Policy</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/contact-us">Contact Us</router-link>
                    </li>
                    <li>
                      <router-link to="/terms-condition"
                        >Terms of Service</router-link
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);">Complaints Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-contents-thirteen-main">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-8">
              <div class="subscribe-form">
                <form action="javascript:void(0)">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email Address"
                  />
                  <button type="submit" class="btn">
                    Subscribe<i class="feather-arrow-right ms-2"></i>
                  </button>
                </form>
              </div>
            </div>
            <div class="col-lg-6 col-md-4">
              <div class="social-icon">
                <ul>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fab fa-facebook"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fab fa-instagram"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fab fa-twitter"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fab fa-linkedin-in"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-12">
              <div class="copyright-text">
                <p class="mb-0">Copyright © 2024 All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
  </footer>
  <!-- /Footer -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>
