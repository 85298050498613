// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/Adolfo/banner/bg-banner.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main-wrapper.home-twelve .finent-veterinary-sec .care-list li span.active[data-v-02afbabf]{background:#14b8a6}.main-wrapper.home-twelve .finent-veterinary-sec .care-list li .care-list-info p[data-v-02afbabf]{margin-bottom:0;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;color:#fff}.section-header-fourteen h2 span[data-v-02afbabf]:before{background:transparent!important}.main-wrapper.home-twelve .clients-section-fourteen[data-v-02afbabf],.main-wrapper.home-twelve .finent-veterinary-sec[data-v-02afbabf]{background:linear-gradient(rgba(47,124,195,.5),rgba(47,124,195,.5)),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@media (max-width:992px){.main-wrapper.home-twelve .finent-veterinary-sec .sec-col-img[data-v-02afbabf]{display:block!important;margin-left:0}.especialidadesImgDoctor[data-v-02afbabf]{display:block!important;position:relative}}@media (max-width:1199px){.client-inner-main[data-v-02afbabf]{display:block!important;position:absolute;z-index:1;margin-left:-100px;margin-top:30px}}@media (max-width:992px){.imgShowDoc2[data-v-02afbabf]{height:150px!important}.client-inner-main[data-v-02afbabf]{display:block!important;position:absolute;z-index:1;margin-left:-100px;margin-top:30px}}@media (max-width:920px){.imgShowDoc2[data-v-02afbabf]{height:100px!important}.client-inner-main[data-v-02afbabf]{display:block!important;position:absolute;z-index:1;margin-left:-60px;margin-top:60px}}@media (max-width:660px){.cardRatingShow[data-v-02afbabf]{padding-top:90px}.imgShowDoc2[data-v-02afbabf]{height:130px!important}.client-inner-main[data-v-02afbabf]{display:block!important;position:absolute;z-index:1;margin-left:150px;margin-top:140px}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
