<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">


    <layoutheader />
    <indexsixbanner />
    <div class="content doctor-content">
      <div class="container">
        <div class="row" style="    margin-top: -130px;
    position: relative;
    z-index: 1;">
          <doctorsidebar></doctorsidebar>
          <div class="col-lg-8 col-xl-9">
  
            <!-- Settings List -->
            <settings-tabs></settings-tabs>
            <!-- /Settings List -->


            <div class="dashboard-header">
              <h5>Actividad Actual</h5>
            </div>

            <div class="doc-information-details" id="membership">
              <div class="member-ship-info">
                <span class="mem-check"><i class="fa-solid fa-check"></i></span>
                <div>
                  <p>
                    Práctica en el medio privado como Cirujano General y Cirujano de Colon y Recto.
                  </p>
                </div>
              </div>
            </div>

            <div class="dashboard-header">
              <h5>Formación Académica</h5>
            </div>

            <div class="doc-information-details" id="membership">
              <div class="member-ship-info">
                <span class="mem-check"><i class="fa-solid fa-check"></i></span>
                <div>
                  <p style="font-weight: bold;">Maestría / Gestión de Instituciones y Centros Hospitalarios (Abril del 2020 – Agosto del 2022)</p> 
                  <p>
                    Universidad del Valle de México (UVM); Campus San Rafael, Cd. de México, México. Cédula Profesional: 13493341
                  </p>
                </div>
              </div>
              <div class="member-ship-info">
                <span class="mem-check"><i class="fa-solid fa-check"></i></span>
                <div>
                  <p style="font-weight: bold;">Posgrado / Clinical Fellowship / Cirugía Colo-Rectal de Mínima Invasión y Robótica (01 Octubre del 2018 - 30 Septiembre del 2019)</p> 
                  <p>
                    AZIENDA ULSS 6 EUGANEA / Hospital de Camposampiero (Centro Regional de Videolaparoscopía y Cirugía Robótica) y Hospital de San Antonio; Padua, Italia
                  </p>
                </div>
              </div>
              <div class="member-ship-info">
                <span class="mem-check"><i class="fa-solid fa-check"></i></span>
                <div>
                  <p style="font-weight: bold;">Posgrado / Sub-especialidad en Coloproctología (2016- 2018)</p> 
                  <p>
                    Instituto Nacional de Ciencias Médicas y Nutrición “Salvador Zubirán” Cd. de México/Universidad Nacional Autónoma de México (UNAM). Cédula Profesional: 10982343
                  </p>
                </div>
              </div>
              <div class="member-ship-info">
                <span class="mem-check"><i class="fa-solid fa-check"></i></span>
                <div>
                  <p style="font-weight: bold;">Posgrado / Especialidad en Cirugía General (2011-2016) </p> 
                  <p style="margin-bottom: 15px;">
                    Instituto Nacional de Ciencias Médicas y Nutrición “Salvador Zubirán” Cd. de México/Universidad Nacional Autónoma de México (UNAM). Cédula Profesional: 10509181
                  </p>
                  <ul>
                    <li>o	2011: Medicina Interna</li>
                    <li>o	2011-2016: Cirugía General</li>
                    <li>o	2015-2016: Jefe de Residentes</li>
                  </ul>
                </div>
              </div>
              <div class="member-ship-info">
                <span class="mem-check"><i class="fa-solid fa-check"></i></span>
                <div>
                  <p style="font-weight: bold;">Licenciatura / Médico Cirujano (2005-2008) con mención como: “Alumno sobresaliente”</p> 
                  <p style="margin-bottom: 15px;">
                    Universidad Autónoma de Guadalajara (UAG); Guadalajara, Jalisco, México. Cédula Profesional: 7057752
                  </p>
                  <ul>
                    <li>o	2009: Internado de pregrado: Instituto Nacional de Ciencias Médicas y Nutrición “Salvador Zubirán” Cd. de México</li>
                    <li>o	2010: Servicio Social en Investigación: Departamento de Cardiología del Instituto Nacional de Ciencias Médicas y Nutrición “Salvador Zubirán” Cd. de México</li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- <form @submit.prevent="submitForm">
              <div class="setting-card">
                <div class="change-avatar img-upload">
                  <div class="profile-img">
                    <i class="fa-solid fa-file-image"></i>
                  </div>
                  <div class="upload-img">
                    <h5>Profile Image</h5>
                    <div class="imgs-load d-flex align-items-center">
                      <div class="change-photo">
                        Upload New
                        <input type="file" class="upload" />
                      </div>
                      <a href="javascript:void(0);" class="upload-remove">Remove</a>
                    </div>
                    <p class="form-text">
                      Your Image should Below 4 MB, Accepted format Jpg,Png,Svg
                    </p>
                  </div>
                </div>
              </div>
              <div class="setting-title">
                <h5>Information</h5>
              </div>
              <div class="setting-card">
                <div class="row">
                  <div class="col-lg-4 col-md-6">
                    <div class="form-wrap">
                      <label class="col-form-label">First Name <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="form-wrap">
                      <label class="col-form-label">Last Name <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="form-wrap">
                      <label class="col-form-label">Display Name <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="form-wrap">
                      <label class="col-form-label">Designation <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="form-wrap">
                      <label class="col-form-label">Phone Numbers <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="form-wrap">
                      <label class="col-form-label">Email Address <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-wrap">
                      <label class="col-form-label">Known Languages <span class="text-danger">*</span></label>
                      <vue3-tags-input class="input-tags form-control" type="text" placeholder="Type"
                        data-role="tagsinput" name="specialist" value="English, German,Portugese" :tags="tags" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="setting-title">
                <h5>Memberships</h5>
              </div>
              <div class="setting-card">
                <div class="add-info membership-infos">
                  <div class="row membership-content" v-for="item in socialMedia" :key="item.id">
                    <div class="col-lg-3 col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label">Title <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" :placeholder="item.placeholder" />
                      </div>
                    </div>
                    <div class="col-lg-9 col-md-6">
                      <div class="d-flex align-items-center">
                        <div class="form-wrap w-100">
                          <label class="col-form-label">About Membership</label>
                          <input type="text" class="form-control" />
                        </div>
                        <div class="form-wrap ms-2">
                          <label class="col-form-label d-block">&nbsp;</label>
                          <a href="javascript:void(0);" class="trash-icon trash"
                            @click="deleteEvent(socialMedia)">Delete</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-end">
                  <a href="javascript:void(0);" class="add-membership-info more-item" @click="add()">Add New</a>
                </div>
              </div>
              <div class="modal-btn text-end">
                <a href="javascript:void(0);" class="btn btn-gray">Cancel</a>
                <button type="submit" class="btn btn-blue">Save Changes</button>
              </div>
            </form> -->
            <!-- /Profile Settings -->
          </div>
        </div>
      </div>
    </div>
    <indexfooter></indexfooter>
    <cursor></cursor>
    <scrolltotop />
  </div>
</template>
<script>
import Vue3TagsInput from "vue3-tags-input";
export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      title: "Curriculum",
      text: "Home",
      text1: "Curriculum",
      tags: ["English", "German", "Portugese"],
      socialMedia: [
        {
          id: 1,
          placeholder: "Add Title",
        },
      ],
    };
  },
  methods: {
    deleteEvent: function (socialMedia) {
      this.socialMedia.splice(this.socialMedia.indexOf(socialMedia), 1);
    },
    add() {
      const newId = this.socialMedia.length + 1;
      this.socialMedia.push({
        id: newId,
        placeholder: "Add Title",
      });
    },
    submitForm() {
      this.$router.push("/doctor-profile-settings");
    },
  },
};
</script>
