<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <bannermaster title="Sobre Mí"/>
    

    <div class="content">
      <div class="container">
        <!-- Doctor Widget -->
        <div class="card doc-profile-card">
          <div class="card-body">
            <div class="doctor-widget doctor-profile-two">
              <div class="doc-info-left">
                <div class="doctor-img">
                  <img
                    src="@/assets/img/doctors/adolfo-perfil-1.jpg"
                    class="img-fluid showDoctor3Img"
                    alt="User Image"
                  />
                </div>
                <div class="doc-info-cont">
                  <span class="badge doc-avail-badge"
                    ><i class="fa-solid fa-circle"></i>Disponible
                  </span>
                  <h4 class="doc-name">
                    Dr. José Adolfo Navarro Navarro
                    <img
                      src="@/assets/img/icons/badge-check.svg"
                      alt="Img"
                    />
                  </h4>
                  <div class="doc-name" style="margin-left: -10px;">
                    <span class="badge doctor-role-badge"><i class="fa-solid fa-circle"></i>Cirujano General y Colo-Proctólogo</span>
                  </div>
                  
                  <p>Lenguajes : Ingles, Español</p>
                  <p class="address-detail" style="font-size: 11px; font-weight: bold;">
                    <span class="loc-icon"><i class="feather-map-pin"></i></span>
                    Tarascos 3473, Monraz, Guadalajara
                    <br>
                    HOSPITAL ÁNGELES DEL CARMEN TORRE ESPECIALIDADES MÉDICAS
                  </p>
                  <a class="view-text" style="font-size: 11px; font-weight: bold;" href="https://www.google.com.mx/maps/place/Torre+de+Especialidades+%C3%81ngeles/@20.6810053,-103.4014754,17z/data=!3m1!4b1!4m6!3m5!1s0x8428ae66cc69f29b:0x257893ca0c6691d2!8m2!3d20.6810003!4d-103.3989005!16s%2Fg%2F1tdmqcgl?entry=ttu">( Ver )</a>
                </div>
              </div>
              <div class="doc-info-right">
                <ul class="doctors-activities">
                  <li>
                    <div class="hospital-info">
                      <span class="list-icon"
                        ><img src="@/assets/img/icons/watch-icon.svg" alt="Img"
                      /></span>
                      <p>No. de cédula: 10982343 10509181 7057752</p>
                    </div>
                    <!-- <ul class="sub-links">
                      <li>
                        <a href="#"><i class="feather-heart"></i></a>
                      </li>
                      <li>
                        <a href="#"><i class="feather-share-2"></i></a>
                      </li>
                      <li>
                        <a href="#"><i class="feather-link"></i></a>
                      </li>
                    </ul> -->
                  </li>
                  <li>
                    <div class="hospital-info">
                      <span class="list-icon"
                        ><img src="@/assets/img/icons/thumb-icon.svg" alt="Img"
                      /></span>
                      <p><b>100% </b> Recomendado</p>
                    </div>
                  </li>
                  <li>
                    <div class="hospital-info">
                      <span class="list-icon"
                        ><img
                          src="@/assets/img/icons/building-icon.svg"
                          alt="Img"
                      /></span>
                      <p>Guadalajara</p>
                    </div>
                  </li>
                  <li>
                    <div class="rating">
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star filled"></i>
                      <span>5.0</span>
                    </div>
                    <!-- <ul class="contact-doctors">
                      <li>
                        <router-link to="/chat-doctor"
                          ><span
                            ><img
                              src="@/assets/img/icons/device-message2.svg"
                              alt="Img" /></span
                          >Chat</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/voice-call"
                          ><span class="bg-violet"
                            ><i class="feather-phone-forwarded"></i></span
                          >Audio Call</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/video-call"
                          ><span class="bg-indigo"
                            ><i class="fa-solid fa-video"></i></span
                          >Video Call</router-link
                        >
                      </li>
                    </ul> -->
                  </li>
                </ul>
              </div>
            </div>
            <div class="doc-profile-card-bottom">
              <ul>
                <li>
                  <span class="bg-blue"
                    ><img src="@/assets/img/icons/calendar3.svg" alt="Img"
                  /></span>
                  Consultorio 610-C
                </li>
                <li>
                  <span class="bg-dark-blue"
                    ><img src="@/assets/img/icons/bullseye.svg" alt="Img"
                  /></span>
                  Experiencia 19 Años
                </li>
                <!-- <li>
                  <span class="bg-green"
                    ><img src="@/assets/img/icons/bookmark-star.svg" alt="Img"
                  /></span>
                  15+ Awards
                </li> -->
              </ul>
              <div class="bottom-book-btn">
                <p><span>Precio : $900 - $1,000 </span>Por tipo de visita</p>
                <!-- <div class="clinic-booking">
                  <a target="_blank" class="apt-btn" href="https://www.doctoralia.com.mx/jose-adolfo-navarro-navarro/proctologo-cirujano-general/guadalajara"
                    > Agendar Citas</a
                  >
                </div> -->
              </div>
              
            </div>
          </div>
          
        </div>
        <!-- <div class="bottom-book-btn">
          <div class="clinic-booking">
            <a :href="'/files/curriculum.pdf'" download class="apt-btn">
              Descargar Curriculum
            </a>
          </div>
        </div> -->
        <!-- /Doctor Widget -->

        <!-- <div class="doc-information-main" style="margin-top: 150px;">
          <div class="doc-information-details bio-detail" id="doc_bio">
            <div class="detail-title">
              <h4>Doctor Bio</h4>
            </div>
            <p>
              “Me considero una persona apasionada de mi especialidad. Siempre en búsqueda de la superación personal.
              Comprometido a dar lo mejor día a día por el beneficio de mis pacientes.”
            </p>
          </div>
        </div> -->
      </div>
    </div>

    <div class="page-wrapper">
      <!-- About Us -->
      <section class="about-section">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
              <div class="about-img-info showVariousDoctors">
                <div class="row">
                  <div class="col-md-6">
                    <div class="about-inner-img">
                      <div class="about-img">
                        <img
                          src="@/assets/img/Adolfo/acerca-de-mi/image0.jpeg"
                          class="img-fluid"
                          alt="Image"
                        />
                      </div>
                      <div class="about-img">
                        <img
                          src="@/assets/img/Adolfo/acerca-de-mi/image3.jpeg"
                          class="img-fluid"
                          alt="Image"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="about-inner-img">
                      <div class="about-box">
                        <h4>Más de 19 años de experiencia</h4>
                      </div>
                      <div class="about-img imgDoctor5">
                        <img
                          src="@/assets/img/Adolfo/acerca-de-mi/image1.jpeg"
                          class="img-fluid"
                          alt="Image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="section-inner-header about-inner-header">
                <h6>Acerca del servicio</h6>
                <h2>
                  Siempre garantizamos el mejor tratamiento médico para su salud.
                </h2>
              </div>
              <div class="about-content">
                <div class="about-content-details">
                  <p>
                    En el campo de la medicina, el compromiso con la excelencia y el bienestar de los pacientes es fundamental. Cada día, trabajo incansablemente para asegurar de que todos aquellos que confían en mi, reciban el mejor tratamiento posible. Este compromiso se refleja en cada consulta, diagnóstico y tratamiento que ofrezco.
                  </p>
                  <p>
                    Desde la primera consulta, me dedico a entender las necesidades individuales de cada persona, lo que nos permite diseñar planes de tratamiento específicos que aborden sus condiciones de salud de manera efectiva y precisa.
                  </p>
                </div>
                <div class="about-contact">
                  <div class="about-contact-icon">
                    <span
                      ><img src="@/assets/img/icons/phone-icon.svg" alt="Image"
                    /></span>
                  </div>
                  <div class="about-contact-text">
                    <p>Necesita emergencia?</p>
                    <h4>+33 382 010 54</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- /About Us -->

      <!-- Why Choose Us -->
      <section class="why-choose-section">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-inner-header text-center">
                <h2>¿ Por que elegirme ?</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-lg-3 col-md-6 d-flex"
              v-for="item in AboutusChoose"
              :key="item.id"
            >
              <div class="card why-choose-card w-100">
                <div class="card-body">
                  <div class="why-choose-icon">
                    <span
                      ><img
                        :src="require(`@/assets/img/icons/${item.Image}`)"
                        alt="Image"
                    /></span>
                  </div>
                  <div class="why-choose-content">
                    <h4>{{ item.Title }}</h4>
                    <p>
                      {{ item.Description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- /Why Choose Us -->

      <!-- Way Section -->
      <!-- <section class="way-section">
        <div class="container">
          <div class="way-bg">
            <div class="way-shapes-img">
              <div class="way-shapes-left">
                <img src="@/assets/img/shape-06.png" alt="Image" />
              </div>
              <div class="way-shapes-right">
                <img src="@/assets/img/shape-07.png" alt="Image" />
              </div>
            </div>
            <div class="row align-items-end">
              <div class="col-lg-7 col-md-12">
                <div class="section-inner-header way-inner-header mb-0">
                  <h2>Be on Your Way to Feeling Better with the Doccure</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <router-link to="/contact-us" class="btn btn-primary"
                    >Contact With Us</router-link
                  >
                </div>
              </div>
              <div class="col-lg-5 col-md-12">
                <div class="way-img">
                  <img
                    src="@/assets/img/way-img.png"
                    class="img-fluid"
                    alt="Image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
      <!-- /Way Choose Us -->

      <!-- Doctors Section -->
      <!-- <section class="doctors-section professional-section">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-inner-header text-center">
                <h2>Best Doctors</h2>
              </div>
            </div>
          </div>
          <div class="row">
           
            <div
              class="col-lg-3 col-md-6 d-flex"
              v-for="item in AboutusDoctors"
              :key="item.id"
            >
              <div class="doctor-profile-widget w-100">
                <div class="doc-pro-img">
                  <router-link to="/doctor-profile">
                    <div class="doctor-profile-img">
                      <img
                        :src="require(`@/assets/img/doctors/${item.Image}`)"
                        class="img-fluid"
                        alt="Image"
                      />
                    </div>
                  </router-link>
                  <div class="doctor-amount">
                    <span>{{ item.Price }}</span>
                  </div>
                </div>
                <div class="doc-content">
                  <div class="doc-pro-info">
                    <div class="doc-pro-name">
                      <router-link to="/doctor-profile">{{
                        item.Name
                      }}</router-link>
                      <p>{{ item.Role }}</p>
                    </div>
                    <div class="reviews-ratings">
                      <p>
                        <span
                          ><i class="fas fa-star"></i> {{ item.Rateing }}</span
                        >
                        {{ item.Views }}
                      </p>
                    </div>
                  </div>
                  <div class="doc-pro-location">
                    <p><i class="feather-map-pin"></i> {{ item.Location }}</p>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section> -->
      <!-- /Doctors Section -->

      <!-- Testimonial Section -->
      <!-- <section class="testimonial-section">
        <div class="testimonial-shape-img">
          <div class="testimonial-shape-left">
            <img src="@/assets/img/shape-04.png" alt="Image" />
          </div>
          <div class="testimonial-shape-right">
            <img src="@/assets/img/shape-05.png" alt="Image" />
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="testimonial-slider slick">
                <Carousel
                  :wrap-around="true"
                  :settings="settings"
                  :breakpoints="breakpoints"
                >
                  <Slide v-for="item in IndexTestimonial" :key="item.id">
                    <div class="testimonial-grid">
                      <div class="testimonial-info">
                        <div class="testimonial-img">
                          <img
                            :src="require(`@/assets/img/clients/${item.Image}`)"
                            class="img-fluid"
                            alt="client-image"
                          />
                        </div>
                        <div class="testimonial-content">
                          <div class="section-inner-header testimonial-header">
                            <h6>Testimonials</h6>
                            <h2>What Our Client Says</h2>
                          </div>
                          <div class="testimonial-details">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Ut enim ad minim veniam,
                              quis nostrud exercitation ullamco laboris nisi ut
                              aliquip ex ea commodo consequat. Duis aute irure
                              dolor in reprehenderit in voluptate velit esse
                              cillum dolore eu fugiat nulla pariatur.
                            </p>
                            <h6>
                              <span>{{ item.Name }}</span> {{ item.Location }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slide>
                  <template #addons>
                    <Navigation />
                  </template>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </section> -->
      <!-- /Testimonial Section -->

      <!-- FAQ Section -->
      <section class="faq-section faq-section-inner">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-inner-header text-center">
                <h6>Obtén las respuestas</h6>
                <h2>Preguntas Frecuentes</h2>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
              <div class="faq-img">
                <img
                  src="@/assets/img/Adolfo/acerca-de-mi/image1.png"
                  class="img-fluid"
                  alt="img"
                />
                <div class="faq-patients-count">
                  <div class="faq-smile-img">
                    <img src="@/assets/img/icons/smiling-icon.svg" alt="icon" />
                  </div>
                  <div class="faq-patients-content">
                    <h4>
                      <span class="count-digit"
                        ><vue3-autocounter
                          class="count-digit"
                          ref="counter"
                          :startAmount="0"
                          :delay="5"
                          :endAmount="70"
                          :duration="5"
                          separator=","
                          :autoinit="true" /></span
                      >+
                    </h4>
                    <p>Pacientes Felices</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="faq-info">
                <div class="accordion" id="accordionExample">
                  <!-- FAQ Item -->
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <a
                        class="accordion-button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        1. ¿Qué es un Cirujano General y Colo-Proctólogo?
                      </a>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="accordion-content">
                          <p>
                            Un Cirujano General se especializa en procedimientos quirúrgicos para tratar una amplia gama de enfermedades. Como Colo-Proctólogo, me especializo en el diagnóstico y tratamiento de trastornos del colon, recto y ano, incluyendo cáncer colorrectal, hemorroides, fisuras anales, enfermedad diverticular, y otros problemas gastrointestinales.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /FAQ Item -->

                  <!-- FAQ Item -->
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <a
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        2. ¿Cuándo debo consultar a un Colo-Proctólogo?
                      </a>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="accordion-content">
                          <p>
                            Debes consultar a un Colo-Proctólogo si experimentas síntomas como dolor rectal, sangrado anal, cambios en los hábitos intestinales, pérdida de peso inexplicada, o si tienes antecedentes familiares de cáncer colorrectal. Es importante un diagnóstico temprano para un tratamiento efectivo.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /FAQ Item -->

                  <!-- FAQ Item -->
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <a
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        3. ¿Cómo puedo prepararme para una cirugía?
                      </a>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="accordion-content">
                          <p>
                            La preparación depende del tipo de cirugía. En general, se te pedirá que evites comer o beber por un periodo específico antes de la cirugía. También se pueden realizar pruebas preoperatorias, y se te proporcionarán instrucciones detalladas sobre los medicamentos que puedes tomar y otras preparaciones necesarias.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /FAQ Item -->

                  <!-- FAQ Item -->
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <a
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        4. ¿Cuánto tiempo dura la recuperación después de una cirugía?
                      </a>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="accordion-content">
                          <p>
                            El tiempo de recuperación varía según el tipo de cirugía y la salud general del paciente. Para procedimientos menores, la recuperación puede ser rápida, mientras que para cirugías más complejas puede llevar varias semanas. Te proporcionaré un plan de recuperación detallado después de la cirugía.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /FAQ Item -->

                  <!-- FAQ Item -->
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <a
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        5. ¿Cómo se detectan las enfermedades del sistema digestivo?
                      </a>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="accordion-content">
                          <p>
                            Las enfermedades del sistema digestivo se detectan mediante una combinación de historial clínico, examen físico, pruebas de laboratorio, estudios de imagen (como ecografías, tomografías y resonancias magnéticas), y procedimientos endoscópicos como la colonoscopia o la endoscopia superior.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /FAQ Item -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- /FAQ Section -->
    </div>
    <indexfooter></indexfooter>
    <cursor></cursor>
    <scrolltotop />
  </div>
</template>

<script>
import axios from 'axios';
import AboutusChoose from "@/assets/json/aboutuschoose.json";
import AboutusDoctors from "@/assets/json/aboutusdoctors";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import IndexTestimonial from "@/assets/json/indextestimonial.json";
import "vue3-carousel/dist/carousel.css";

export default {
  data() {
    return {
      filesrcs: [
        {
          title: 'curriculum.docx',
        },
      ],
      AboutusChoose: AboutusChoose,
      AboutusDoctors: AboutusDoctors,
      title: "Acerca de mi",
      text: "Home",
      text1: "Acerca de mi",
      IndexTestimonial: IndexTestimonial,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
        },
      },
    };
  },
  methods: {
    downloadPdf() {

      /* this.axios.get({
          '@/assets/files/curriculum.docx',
          responseType: 'arraybuffer',
        })
          .then((response) => {
            this.forceFileDownload(response, title)
          })
          .catch(() => console.log('error occured'))
      }, */

      /* this.axios.get(api).then((response) => {
        console.log(response.data)
      }) */

    },
    forceFileDownload(response, title) {
      console.log(title)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>

<style scoped>

@media (max-width: 991px) {

  .showVariousDoctors{
    display: block !important;
    margin-bottom: 100px !important;
  }

}

@media (max-width: 767px) {

  .showDoctor3Img{
    height: 135px !important;
  }

  .about-box {
    display: none !important;
  }

  .about-img {
    justify-content: center !important;
    display: flex !important;
  }

  .imgDoctor5 {
    margin-top: 25px !important;
  }

}
</style>
