<template>
  <!-- Home Banner -->
  <section class="banner-section-fourteen banner-section-twelve" style="padding-top: 200px;">
    <div class="banner-section-twelve-bg">
      <img src="@/assets/img/bg/home-12-banner-bg-1.png" alt="design-image" />
      <img src="@/assets/img/bg/home-12-banner-bg-2.png" alt="design-image" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div
            class="banner-content banner-content-fourteen aos"
            data-aos="fade-up"
          >
            <h1>Dr. José Adolfo Navarro Navarro</h1>
            <p>
              Cirujano General y Colo-Proctólogo <br> Cirugía laparoscópica y robótica
            </p>
                
            <div class="banner-btns-fourteen">
              <router-link to="/curriculum-educacion" class="btn btn-primary"
                >Conoceme más</router-link
              >
              <!-- <router-link to="/booking" class="btn btn-primary"
                >Schedule a Call</router-link
              > -->
            </div>
          </div>
          <!-- <div class="search-box-fourteen aos" data-aos="fade-up">
            <form @submit.prevent="submitForm" class="form-block d-flex">
              <div class="search-input">
                <div class="forms-block">
                  <label class="mb-0">Date</label>
                  <datepicker
                    v-model="startdate"
                    placeholder="Thu, Mar 24, 2023"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    :input-format="dateFormat"
                  />
                </div>
              </div>
              <div class="search-input">
                <div class="forms-block mb-0">
                  <label class="location-icon">Location</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="San Diego Branch"
                  />
                </div>
              </div>
              <div class="search-btn">
                <button class="btn btn-primary" type="submit">
                  Book Appointment
                </button>
              </div>
            </form>
          </div> -->
        </div>
        <div class="col-lg-6">
          <div class="banner-img banner-img-twelve aos" data-aos="fade-up">
            <!-- <img
              src="@/assets/img/bg/home-12-banner-1.png"
              class="img-fluid"
              alt="dog-image"
            />
            <img
              src="@/assets/img/Adolfo/inicio/image5_preview_rev_1.png"
              class="img-fluid"
              alt="cat-image"
            /> -->
            <div class="banner-banner-img-twelve-bg">
              <img src="@/assets/img/bg/dot-1.png" alt="dot-icon" />
              <img src="@/assets/img/bg/dot-2.png" alt="dot-icon" />
              <img src="@/assets/img/bg/ring-1.png" alt="ring-icon" />
              <img src="@/assets/img/bg/ring-2.png" alt="ring-icon" />
            </div>
          </div>
        </div>
        <div class="col-md-12 d-flex justify-content-end">
          <div class="badge-info-bottom">
            <span style="background: white !important;"
              ><img src="@/assets/img/Adolfo/iconos/intestino.png" style="height: 35px !important;" alt="Img"
            /></span>
            <h6>Confía en la experiencia para tu salud intestinal.</h6>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Home Banner -->

  <!-- services Section -->
  <section class="services-section-fourteen">
    <!-- <div class="floating-bg">
      <img src="@/assets/img/Adolfo/iconos/cirugia-plastica.png" style="height: 60px; opacity: 0.2;" alt="paw-image" />
      <img src="@/assets/img/Adolfo/iconos/intestino.png" style="height: 60px; opacity: 0.2;" alt="paw-image" />
    </div> -->
    <div class="container">
      <div class="row">
        <div class="col-lg-12 aos" data-aos="fade-up">
          <div class="section-header-fourteen service-inner-fourteen">
            <h2>Servicios</h2>
            <p>
              Mis servicios están diseñados para atender una variedad de condiciones, Mi compromiso es ofrecer un trato humano, empático y profesional
            </p>
          </div>
        </div>
      </div>
      <!-- Section Carousel Start -->
      <section class="clinics-section" style="background: transparent;">
        <div class="shapes">
          <!-- <img
            src="@/assets/img/shapes/shape-1.png"
            alt="Image"
            class="img-fluid shape-1"
          />
          <img
            src="@/assets/img/shapes/shape-2.png"
            alt="Image"
            class="img-fluid shape-2"
          /> -->
        </div>
        <div class="container">
          <div class="owl-carousel clinics owl-theme">
            <Carousel
              :wrap-around="true"
              :settings="settings"
              :breakpoints="breakpoints"
            >
              <Slide v-for="item in indexThreeBanner" :key="item.id">

                <div class="item">
                  <div @click="sendInfoServicesDetails(item)">
                    <div class="clinic-item">
                      <div class="clinics-card">
                        <div class="clinics-img" style="height: 250px;">
                          <img
                            :src="require(`@/assets/img/Adolfo/servicios/${item.Image}`)"
                            alt="Image"
                            style="height: 250px; object-fit: cover;"
                          />
                        </div>
                        <div class="clinics-info">
                          <span class="clinic-img">
                            <img
                              :src="require(`@/assets/img/Adolfo/iconos/${item.Logo}`)"
                              alt="kidney-image"
                              class="img-fluid"
                              style="width: 40px !important;"
                            />
                          </span>
                          <a href="#" style="text-decoration: none !important;"
                            ><span>{{ item.Title }}</span></a
                          >
                        </div>
                      </div>
                      <div class="clinics-icon">
                        <a href="javascript:;"
                          ><i class="fas fa-long-arrow-alt-right"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </section>
      <!-- Section Carousel End -->
      <div class="row row-gap justify-content-center">
        <div class="owl-carousel clinics owl-theme">
          
        </div>
        <!-- <div
          class="col-lg-3 col-md-4 col-sm-12"
          v-for="service in indexsevenservice"
          :key="service.title"
        >
          <div class="our-services-list w-100">
            <div class="service-icon">
              <img
                :src="require(`@/assets/img/icons/${service.icon}`)"
                :alt="service.alt"
              />
            </div>
            <h4>{{ service.title }}</h4>
            <p>{{ service.description }}</p>
            <a
              href="#"
              class="more-btn overlay-btns d-inline-flex align-items-center"
              >Learn More<i class="fa-solid fa-circle-arrow-right ms-2"></i
            ></a>
          </div>
        </div> -->
      </div>
    </div>
    <div class="marquee-title">
      <h3>
        Cuidando tu salud, mejorando tu vida...   Salud intestinal, vida plena...
      </h3>
    </div>
    <!-- <div class="container">
      <div class="best-quality-sec">
        <div class="row">
          <div class="col-lg-5">
            <div class="img-col">
              <img
                src="@/assets/img/bg/best-quality-sec-img.png"
                class="img-fluid"
                alt="Img"
              />
            </div>
          </div>
          <div class="col-lg-7 aos" data-aos="fade-up">
            <div class="quality-service-info">
              <div class="section-header-fourteen service-inner-fourteen">
                <h2>
                  Our mission is to provide the best quality services to your
                  Pets
                </h2>
                <p>
                  Treating animals and we are always excited to add to our
                  ever-expanding family. We thank you for consider us and we
                  look forward to hearing from you soon.
                </p>
              </div>
              <ul class="pets-service-list">
                <li>
                  <h3 class="text-blue">
                    <span class="counter animated fadeInDownBig">
                      <vue3-autocounter
                        ref="counter"
                        :startAmount="1"
                        :delay="3"
                        :endAmount="3564"
                        :duration="5"
                        separator=","
                        :autoinit="true" />
                      </span>+
                  </h3>
                  <p>
                    The number of pets successfully treated in our vet clinic
                  </p>
                </li>
                <li>
                  <h3 class="text-yellow">
                    <span class="counter animated fadeInDownBig">
                      <vue3-autocounter
                        ref="counter"
                        :startAmount="1"
                        :delay="3"
                        :endAmount="1654"
                        :duration="5"
                        separator=","
                        :autoinit="true" />
                      </span>+
                  </h3>
                  <p>Happy global customers who trusted us with ease</p>
                </li>
              </ul>
              <router-link to="/search-2" class="learn-more-btn btn btn-primary"
                >Learn More</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </section>
  <!-- /services Section -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import indexsevenservice from "@/assets/json/indexsevenservice.json";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexThreeBanner from "@/assets/json/indexthreebanner.json";
import "vue3-carousel/dist/carousel.css";
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      indexsevenservice: indexsevenservice,
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      indexThreeBanner: indexThreeBanner,
      settings: {
        itemsToShow: 5,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 5,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 5,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 5,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 5,
          snapAlign: "center",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    submitForm() {
      this.$router.push("/search");
    },
    sendInfoServicesDetails(info) {
      console.log(info);
      this.$router.push({ path: `/detalle-servicios/${info.Title}`, params: info })
    }
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>


<style scoped>
.main-wrapper.home-twelve .banner-section-fourteen.banner-section-twelve{
    background: linear-gradient(rgba(47,124,195,.5), rgba(47,124,195,.5)), url("@/assets/img/Adolfo/banner/bg-banner.jpg");
    background-size: cover;
    background-position: center;
    background-color: #2f7cc3;
    padding: 125px 0;
}

.main-wrapper.home-twelve .services-section-fourteen .marquee-title h3 {
    animation: marquee 15s linear infinite;
    background: linear-gradient(90deg, #0284C7 16.05%, #14ffeb 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    margin-bottom: 0;
}
</style>
