<template>
  <!-- Footer -->
  <footer class="footer footer-fifteen">
			<div class="footer-top footer-top-fifteen aos" data-aos="fade-up">
				<div class="container">
					<div class="row join-news-row">
						<div class="col-lg-8 col-md-6">
							<div class="join-news-foot">
								<h4>Join Our Newsletter</h4>
								<p>Subscribe Now for Exclusive Insights & Offers</p>
							</div>
						</div>
						<div class="col-lg-4 col-md-6">
							<div class="form-set">
								<form @submit.prevent="submitForm">
									<input type="text" class="form-control" placeholder="Enter your Email">
									<button type="submit" class="btn btn-primary">Subscribe</button>
								</form>
							</div>
							
						</div>
					</div>
					<div class="row">
						<div class="col-lg-4 col-md-6">
							<div class="footer-widget">
								<div class="footer-logo">
									<img src="@/assets/img/logo-15.png" alt="Img">
								</div>
								<p>Effortlessly schedule your medical appointments with Doccure. 
									Connect with healthcare professionals, manage appointments & prioritize your well being 
								</p>
								<div class="social-links">
									<ul>
										<li><a href="javascript:void(0);"><i class="fa-brands fa-facebook-f"></i></a></li>
										<li><a href="javascript:void(0);"><i class="fa-brands fa-instagram"></i></a></li>
										<li><a href="javascript:void(0);"><i class="fa-brands fa-twitter"></i></a></li>
										<li><a href="javascript:void(0);"><i class="fa-brands fa-linkedin-in"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-2 col-sm-6">
							<div class="footer-widget">
								<div class="footer-title">
									<h4>Quick Links</h4>
								</div>
								<ul class="link-foot">
									<li><router-link to="/about-us">About</router-link></li>
									<li><a href="javascript:void(0);">Faciities</a></li>
									<li><router-link to="/my-patients">Patients</router-link></li>
									<li><a href="javascript:void(0);">Camp</a></li>
									<li><router-link to="/doctor-profile">Doctors</router-link></li>
								</ul>
							</div>
						</div>
						<div class="col-lg-3 col-sm-6">
							<div class="footer-widget">
								<div class="footer-title">
									<h4>Services</h4>
								</div>
								<ul class="link-foot">
									<li><a href="javascript:void(0);">Hearing and Balance Disorders</a></li>
									<li><a href="javascript:void(0);">Snoring and Sleep Apnea</a></li>
									<li><a href="javascript:void(0);">Voice and Swallowing Disorders</a></li>
									<li><a href="javascript:void(0);">Sinusitis and Rhinitis</a></li>
									<li><a href="javascript:void(0);">Allergy Testing and Treatment</a></li>
								</ul>
							</div>
						</div>
						<div class="col-lg-3 col-sm-6">
							<div class="footer-widget">
								<div class="footer-title">
									<h4>Contact</h4>
								</div>
								<ul class="link-foot address">
									<li>
										<span>
											<i class="fa-solid fa-location-dot"></i>
										</span>
										3556 Beech Street, San Francisco, California, CA 94108
									</li>
									<li>
										<span>
											<i class="fa-solid fa-mobile-screen-button"></i>
										</span>
										+1 315 369 5943
									</li>
									<li>
										<span>
											<i class="fa-solid fa-envelope"></i>
										</span>
										doccure@example.com
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer-bottom">
				<div class="container">
					<div class="bottom-items">
						<div class="row">
							<div class="col-md-6">
								<div class="copy-right">
									<p>Copyright © {{ new Date().getFullYear() }} Doccure. All Rights Reserved</p>
								</div>
							</div>
							<div class="col-md-6">
								<div class="terms-con">
									<ul>
										<li>
											<router-link to="/terms-condition">Terms and Conditions</router-link>
										</li>
										<li>
											<router-link to="/privacy-policy">Policy</router-link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
  <!-- /Footer -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  data() {
    return {
      English: ["English", "Spanish", "Mexico"],
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  methods: {
	submitForm() {
      this.$router.push("/index-10");
    },
  },
};
</script>
