<template>
  <section class="section seller-section">
    <div class="container">
      <div class="seller-info">
        <div class="row">
          <div class="col-xl-3 col-lg-6 col-md-6 d-flex">
            <div class="seller-card flex-fill">
              <div class="seller-content">
                <h6>Medical Pack</h6>
                <h4>Get offers upto 25%</h4>
                <router-link to="/product-all" class="btn">Grab Offer Now</router-link>
              </div>
              <div class="seller-time">
                <ul class="nav">
                  <li>
                    <h5>06</h5>
                    <p>Days</p>
                  </li>
                  <li>
                    <h5>16</h5>
                    <p>Hours</p>
                  </li>
                  <li>
                    <h5>54</h5>
                    <p>Minutes</p>
                  </li>
                  <li>
                    <h5>16</h5>
                    <p>Seconds</p>
                  </li>
                </ul>
              </div>
              <div class="seller-img">
                <img src="@/assets/img/seller-img.png" alt="Seller Img" />
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6">
            <div class="seller-title">
              <h4>Hot Deals</h4>
            </div>
            <div class="seller-grid">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img src="@/assets/img/seller/seller-01.png" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>Home & Health</h6>
                <h5>
                  <router-link to="/product-all">Vitamin Tablets</router-link>
                </h5>
                <p>$200 <span>$300</span></p>
              </div>
            </div>
            <div class="seller-grid">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img src="@/assets/img/seller/seller-02.png" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>Ayush</h6>
                <h5>
                  <router-link to="/product-all">Natural Tooth Paste</router-link>
                </h5>
                <p>$50</p>
              </div>
            </div>
            <div class="seller-grid">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img src="@/assets/img/seller/seller-03.png" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>Baby Care</h6>
                <h5>
                  <router-link to="/product-all">Baby Kit</router-link>
                </h5>
                <p>$270</p>
              </div>
            </div>
            <div class="seller-grid">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img src="@/assets/img/seller/seller-04.png" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>Eye Glasses</h6>
                <h5>
                  <router-link to="/product-all">Round Frame Glass</router-link>
                </h5>
                <p>$450 <span>$500</span></p>
              </div>
            </div>
            <div class="seller-grid">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img src="@/assets/img/seller/seller-05.png" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>Devices</h6>
                <h5>
                  <router-link to="/product-all">Infrared Thermometer</router-link>
                </h5>
                <p>$300 <span>$400</span></p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6">
            <div class="seller-title">
              <h4>Best Sellers</h4>
            </div>
            <div class="seller-grid">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img src="@/assets/img/seller/seller-06.png" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>Hands & Feet</h6>
                <h5>
                  <router-link to="/product-all">Hand Washing Soap</router-link>
                </h5>
                <p>$150 <span>$200</span></p>
              </div>
            </div>
            <div class="seller-grid">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img src="@/assets/img/seller/seller-07.png" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>Covid Essentials</h6>
                <h5>
                  <router-link to="/product-all">Hand Sanitizer</router-link>
                </h5>
                <p>$100</p>
              </div>
            </div>
            <div class="seller-grid">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img src="@/assets/img/seller/seller-08.png" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>Glucometer</h6>
                <h5>
                  <router-link to="/product-all">Glucometer</router-link>
                </h5>
                <p>$200 <span>$300</span></p>
              </div>
            </div>
            <div class="seller-grid">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img src="@/assets/img/seller/seller-09.png" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>Weight</h6>
                <h5>
                  <router-link to="/product-all">Weight Machine</router-link>
                </h5>
                <p>$800</p>
              </div>
            </div>
            <div class="seller-grid">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img src="@/assets/img/seller/seller-10.png" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>Home & Health</h6>
                <h5>
                  <router-link to="/product-all">Face Cream</router-link>
                </h5>
                <p>$70</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6">
            <div class="seller-title">
              <h4>Top Rated</h4>
            </div>
            <div class="seller-grid">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img src="@/assets/img/seller/seller-11.png" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>Oral Care</h6>
                <h5>
                  <router-link to="/product-all">Electric Tooth Brush</router-link>
                </h5>
                <p>$60 <span>$100</span></p>
              </div>
            </div>
            <div class="seller-grid">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img src="@/assets/img/seller/seller-12.png" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>Devices</h6>
                <h5>
                  <router-link to="/product-all">sphygmomanomet</router-link>
                </h5>
                <p>$450 <span>$500</span></p>
              </div>
            </div>
            <div class="seller-grid">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img src="@/assets/img/seller/seller-13.png" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>Covid Essentials</h6>
                <h5>
                  <router-link to="/product-all">Hand Gloves</router-link>
                </h5>
                <p>$20</p>
              </div>
            </div>
            <div class="seller-grid">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img src="@/assets/img/seller/seller-14.png" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>Hands & Feet</h6>
                <h5>
                  <router-link to="/product-all">Body Lotion</router-link>
                </h5>
                <p>$120 <span>$150</span></p>
              </div>
            </div>
            <div class="seller-grid">
              <div class="seller-icon">
                <router-link to="/product-all">
                  <img src="@/assets/img/seller/seller-15.png" alt="Seller Icon" />
                </router-link>
              </div>
              <div class="seller-text">
                <h6>Women’s Care</h6>
                <h5>
                  <router-link to="/product-all">Calcium Tablets</router-link>
                </h5>
                <p>$170 <span>$200</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
