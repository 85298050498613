<template>
    <layoutheader />

    <indexbanner />

    <indexdoctor />

    <indexwork />

    <indexapp />

    <indextestimonial />

    <indexpartners />
    <cursor />
    <footerindex />

    <scrolltotop />
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
}
</script>
