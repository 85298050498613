<template>
  <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
    <div class="stickysidebar">
      <!-- Profile Sidebar -->
      <div class="profile-sidebar doctor-sidebar profile-sidebar-new">
        <div class="widget-profile pro-widget-content">
          <div class="profile-info-widget">
            <div class="booking-doc-img">
              <img src="@/assets/img/Adolfo/acerca-de-mi/image4.png" alt="User Image" style="object-position: left;" />
            </div>
            <div class="profile-det-info">
              <h3>Dr. José Adolfo Navarro Navarro</h3>
              <div class="patient-details">
                <h5 class="mb-0">Cirujano General & Colo-Proctólogo</h5>
              </div>
              <span class="badge doctor-role-badge"><i class="fa-solid fa-circle"></i>Doctor</span>
            </div>
          </div>
        </div>
        <div class="doctor-available-head" style="background: #0e82fd; padding: 10px; padding-left: 24px;">
          <div class="input-block input-block-new">
            <label class="form-label" style="color: white;">CONTACTO</label>
          </div>
        </div>
        <div class="dashboard-widget">
          <nav class="dashboard-menu">
            <ul>
              <div class="widget education-widget">
                <div class="experience-box">
                  <ul class="experience-list">
                    <li>
                      <div class="experience-user">
                        <div class="before-circle"></div>
                      </div>
                      <div class="experience-content">
                        <div class="timeline-content">
                          <a class="name">Dirección:</a>
                          <div>
                            “Hospital Ángeles del Carmen”
                            Torre de especialidades médicas.
                            Guadalajara Jalisco
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="experience-user">
                        <div class="before-circle"></div>
                      </div>
                      <div class="experience-content">
                        <div class="timeline-content">
                          <a class="name">Cel:</a>
                          <div> (33) 38 20 10 54</div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="experience-user">
                        <div class="before-circle"></div>
                      </div>
                      <div class="experience-content">
                        <div class="timeline-content">
                          <a class="name">E-mail:</a>
                          <div style="font-size: 12px;">dr.anavarro@icloud.com</div>
                          <div style="font-size: 12px;">dr.joseadolfonavarronavarro@gmail.com</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </ul>
          </nav>
        </div>
        <div class="doctor-available-head" style="background: #0e82fd; padding: 10px; padding-left: 24px;">
          <div class="input-block input-block-new">
            <label class="form-label" style="color: white;"></label>
          </div>
        </div>
        <div class="dashboard-widget">
          <nav class="dashboard-menu">
            <ul>
              <div class="widget education-widget">
                <div class="experience-box">
                  <ul class="experience-list">
                    <li>
                      <div class="experience-user">
                        <div class="before-circle"></div>
                      </div>
                      <div class="experience-content">
                        <div class="timeline-content">
                          <div>
                            Cirujano experto
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="experience-user">
                        <div class="before-circle"></div>
                      </div>
                      <div class="experience-content">
                        <div class="timeline-content">
                          
                          <div>Manejo de tecnologíaS avanzadas</div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="experience-user">
                        <div class="before-circle"></div>
                      </div>
                      <div class="experience-content">
                        <div class="timeline-content">
                          <div >Instalaciónes quirúrgicas de alta calidad</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </ul>
          </nav>
        </div>
      </div>
      <!-- /Profile Sidebar -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeClass: "active",
      Available: ["I am Available Now", "Not Available"]
    };
  },
  methods: {
    isActive(paths) {
      return paths.includes(this.$route.path);
    },
  },
};
</script>


<style scoped>
.profile-sidebar.profile-sidebar-new .widget-profile .profile-info-widget::after{
  background-image: url("@/assets/img/Adolfo/logo/doctor-sidebar-bg.jpg") !important; 
}
</style>
