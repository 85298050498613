<template>
  <!-- Home Banner -->
  <section class="doctor-search-section doctor-search-eleven bgBanner-Master">
    <div class="container bannermasterstyle" style="margin-top: 100px;">
      <div class="row align-items-center">
        <div class="col-lg-6 aos" data-aos="fade-up">
          <div class="banner-header" style=" margin-bottom: 0px;
    padding-bottom: 0px;">
            <h1>{{ title }}</h1>
          </div>
        </div></div>
    </div></section>
  <!-- /Home Banner -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexSixBanner from "@/assets/json/indexsixbanner.json";
import "vue3-carousel/dist/carousel.css";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    text1: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      indexSixBanner: indexSixBanner,
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
  methods: {
    submitForm() {
      this.$router.push("/booking-2");
    },
  },
};
</script>


<style scoped>
.doctor-search-section.doctor-search-eleven {
  background: linear-gradient(rgba(47,124,195,.5), rgba(47,124,195,.5)), url("@/assets/img/Adolfo/banner/bg-banner.jpg") !important;
}
@media (max-width: 992px) {

  .bannermasterstyle {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    
  }

  .banner-header {
    display: flex;
    justify-content: center;
  }

  

}
</style>
