<template>
    <!-- Lab Service Section -->
    <section class="lab-service-section">
        <div class="sec-bg">
            <img src="@/assets/img/icons/section-small-icon-02.svg" class="small-icon-two" alt="Img">
        </div>
        <div class="lab-services">
            <div class="row">
                <div class="col-lg-6 d-flex">
                </div>
                <div class="col-lg-6 d-flex">
                    <div class="servive-provider-content">
                        <div class="lab-service-title">
                            <h2>Versatile Laboratory Service Provider</h2>
                            <h5>Your full service lab for clinical trials. Our mission is to ensure the generation of
                                accurate and precise findings</h5>
                            <p>One of the most popular services Doccure offers is diagnostic testing. People need to book
                                pathological tests for many reasons. But before booking, they wonder if the diagnostic
                                centre can be trusted to produce accurate results...</p>
                        </div>
                        <div class="lab-service-features">
                            <ul>
                                <li>
                                    <span>
                                        <img src="@/assets/img/icons/lab-service-icon-01.svg" alt="Img">
                                    </span>
                                    <h6>The Doccure - Easy Way To Book Lab Tests Online</h6>
                                </li>
                                <li>
                                    <span>
                                        <img src="@/assets/img/icons/lab-service-icon-02.svg" alt="Img">
                                    </span>
                                    <h6>Lab Test Booking Made Simple</h6>
                                </li>
                                <li>
                                    <span>
                                        <img src="@/assets/img/icons/lab-service-icon-03.svg" alt="Img">
                                    </span>
                                    <h6>World class Laboratory support</h6>
                                </li>
                            </ul>
                        </div>
                        <div class="sec-btn">
                            <router-link to="/voice-call" class="btn btn-primary">Talk With Expert</router-link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <!-- /Lab Service Section -->
</template>